import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import swal from 'sweetalert';
import NavBar from './NavBar/NavBar';
import Footer from './Footer/Footer';

const LoginChangeContent = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);  // Added loading state
  const { login } = useContext(AuthContext);  // Access the login function from context
  const navigate = useNavigate();

  const handleLogin = async () => {
    // Check if the form is filled
    if (!username || !password) {
      swal("Error", "Please fill in both fields", "error");
      return;
    }

    setLoading(true);  // Show loading indicator

    try {
      const response = await axios.post('https://backendforerpsystem.zbooma.com/LoginChangeContent.php', {
        username,
        password,
      });

      // Handle successful login
      if (response.data.success) {
        login(response.data.token);  // Save token or user data in context
        swal("Success", "Login successful!", "success").then(() => {
          navigate('/changeContent');  // Navigate after alert
        });
      } else {
        swal("Error", response.data.message || "Invalid credentials", "error");
      }
    } catch (error) {
      console.error('Error logging in:', error);
      swal("Error", "An error occurred while logging in. Please try again.", "error");
    } finally {
      setLoading(false);  // Stop loading
    }
  };

  return (
    <> 
      <NavBar/>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '70vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '400px',
          padding: '20px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#fff',
          borderRadius: '8px',
        }}
      >
        <Typography variant="h5" textAlign="center" sx={{ marginBottom: '20px' }}>
          Login to Change Content
        </Typography>

        <TextField
          label="Username"
          variant="outlined"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          disabled={loading}  // Disable while loading
        />

        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}  // Disable while loading
        />

        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: '20px' }}
          onClick={handleLogin}
          disabled={loading}  // Disable button while loading
        >
          {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Login'}
        </Button>
      </Box>
    </Box>
      <Footer/>
    </>
  );
};

export default LoginChangeContent;
