import React, { useState, useEffect } from 'react';
import './ProfessionalSectionFourth.css';
import LazyImage from '../../../Components/LazyImage';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

// Define the base URL for images
const baseURL = 'https://backendforerpsystem.zbooma.com';

const ProfessionalSectionFourth = () => {
  const [tabs, setTabs] = useState([]); // State to hold tabs and images from API
  const [activeTab, setActiveTab] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  // Fetch data from API
  useEffect(() => {
    setLoading(true); // Start loading
    fetch('https://backendforerpsystem.zbooma.com/get_services_images_protofilo.php')
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          // Process the data to handle both relative and absolute image paths
          const processedData = data.data.map(tab => ({
            ...tab,
            images: tab.images.map(image => 
              image.startsWith('/') ? `${baseURL}${image}` : image
            ),
          }));
          
          setTabs(processedData); // Set the processed data to the tabs state
          setActiveTab(processedData[0].tab_id); // Set the first tab as active by default
        }
        setLoading(false); // Stop loading after data is set
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    setShowAll(false); // Reset showAll when changing tabs
    setLoading(true); // Show loading when switching tabs

    // Simulate loading delay
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleShowAllClick = () => {
    setShowAll(true);
  };

  const handleShowLessClick = () => {
    setShowAll(false);
  };

  const handleImageClick = (image) => {
    setModalImage(image);
  };

  const handleCloseModal = () => {
    setModalImage(null);
  };

  const activeTabData = tabs.find((tab) => tab.tab_id === activeTab);
  const imagesToShow = showAll ? activeTabData?.images : activeTabData?.images.slice(0, 4);

  return (
    <div className="professional-section-fourth">
      <div className="tabs">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab ${tab.tab_id === activeTab ? 'active' : ''}`}
            onClick={() => handleTabClick(tab.tab_id)}
          >
            {tab.label}
          </div>
        ))}
      </div>

      {loading ? (
        // Spinner displayed in the center while loading
        <div className="spinner-container">
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <>
          <div className="images-container">
            {imagesToShow.map((image, index) => (
              <LazyImage
                key={index}
                src={image} // Already processed to handle both relative and absolute URLs
                alt={`Image ${index + 1}`}
                onClick={() => handleImageClick(image)}
              />
            ))}
          </div>
          <div className="buttons-container">
            {!showAll && (
              <button className="button all-button" onClick={handleShowAllClick}>SHOW ALL</button>
            )}
            {showAll && (
              <button className="button all-button" onClick={handleShowLessClick}>SHOW LESS</button>
            )}
            <button className="button contact-button">CONTACT US</button>
          </div>
        </>
      )}

      <Dialog
        open={Boolean(modalImage)}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h6">Image Preview</Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <img src={modalImage} alt="Modal" style={{ width: '100%' }} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProfessionalSectionFourth;
