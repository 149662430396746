import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Typical from 'react-typical';
import { useNavigate } from 'react-router-dom';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import axios from 'axios';
import { ThemeContext } from '../ThemeContext'; // Import ThemeContext

const HeroSection = () => {
  const [heroData, setHeroData] = useState(null); // State to hold the fetched data
  const navigate = useNavigate();
  const { themeMode } = useContext(ThemeContext); // Use themeMode from context
  const isDarkMode = themeMode === 'dark';

  const baseURL = 'https://backendforerpsystem.zbooma.com/'; // The base URL for images

  useEffect(() => {
    // Fetch data from the API when the component mounts
    const fetchHeroData = async () => {
      try {
        const response = await axios.get('https://backendforerpsystem.zbooma.com/getHeroSection.php');
        if (response.data.success && response.data.data.length > 0) {
          setHeroData(response.data.data[0]); // Store the fetched data in state
        }
      } catch (error) {
        console.error('Error fetching hero section data:', error);
      }
    };

    fetchHeroData();
  }, []);

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/966553165725', '_blank');
  };

  const handleBookingClick = () => {
    navigate('/login');
  };

  if (!heroData) {
    return <div>Loading...</div>; // Show a loading message while data is being fetched
  }

  return (
    <div>
      <Box
        sx={{
          position: 'relative',
          height: { xs: '75vh', md: '100vh' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          backgroundImage: `url(${baseURL}${heroData.background_image_url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: '#fff',
          padding: (theme) => theme.spacing(3),
        }}
      >
        <Box sx={{
          transform: {
            xs: 'scale(1)', // Default scale for mobile and smaller screens
            md: 'scale(0.7)' // Scale down to 80% for laptop screens (md breakpoint and above)
          },
          transformOrigin: 'center',
          width: '100%',
        }}>
          <Box 
            component="img" 
            src={`${baseURL}${heroData.first_image_url}`} 
            alt='imagefirst'
            sx={{
              width: { xs: '50%', md: 'auto' }, // 50% width on small screens, auto on larger screens
              maxWidth: '240px', // Set a max width to control how large it can get
              marginBottom: { xs: '10px', md: '0' } // Adjust margin for small screens
            }}
          />
          <br/>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: isDarkMode ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0)', // Apply dark mode overlay
              zIndex: 1,
            }}
          ></Box>
          <Box sx={{ position: 'relative', zIndex: 2 }}>
            <Typography variant="h1" sx={{ fontFamily: '"MO_Nawel", sans-serif', fontWeight: 550, fontSize: { xs: '2rem', md: '5rem' } }}>
              <Typical
                steps={heroData.typical_typography.flatMap(step => [step.text, step.delay, 3000])} // Use data from API
                loop={Infinity}
                wrapper="span"
              />
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontFamily: '"Cairo", sans-serif',
                margin: '20px 0',
                fontSize: { xs: '1rem', md: '1.5rem' },
                animation: 'fadeIn 1s ease-in-out',
              }}
            >
              {heroData.main_typography}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px', flexWrap: 'wrap' }}>
              <Button
                variant={isDarkMode ? 'outlined' : 'outlined'}
                sx={{
                  borderColor: isDarkMode ? '#fff' : '#fff',
                  color: isDarkMode ? '#fff' : '#fff',
                  backgroundColor: isDarkMode ? 'transparent' : '#transparent',
                  padding: '10px 20px',
                  fontSize: { xs: '1rem', md: '1.2rem' },
                  fontFamily: '"Cairo", sans-serif',
                  width: { xs: '150px', md: '200px' },
                  cursor: 'pointer',
                  '&:hover': {
                    borderColor: isDarkMode ? '#fff' : '#fff',
                    backgroundColor: isDarkMode ? 'transparent' : 'transparent',
                  }
                }}
                onClick={handleBookingClick}
              >
                {heroData.button2_text}
              </Button>
              <Button
                variant={isDarkMode ? 'outlined' : 'contained'}
                startIcon={<WhatsAppIcon />}
                sx={{
                  borderColor: isDarkMode ? '#fff' : '#25D366',
                  color: isDarkMode ? '#fff' : '#fff',
                  backgroundColor: isDarkMode ? 'transparent' : '#25D366',
                  padding: '10px 20px',
                  fontSize: { xs: '1rem', md: '1.2rem' },
                  fontFamily: '"Cairo", sans-serif',
                  width: { xs: '150px', md: '200px' },
                  cursor: 'pointer',
                  '&:hover': {
                    borderColor: isDarkMode ? '#fff' : '#1ebd59',
                    backgroundColor: isDarkMode ? 'transparent' : '#1ebd59',
                  }
                }}
                onClick={handleWhatsAppClick}
              >
                {heroData.button1_text}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default HeroSection;
