import React from 'react';
import { Box, TextField, Button, Typography, Grid } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

const ContactFormNew = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#30355b',
        padding: '40px 20px',
        margin: '0 auto',
        color: 'white',
        marginTop: { xs: '0', md: '0' }, // Add top margin on small screens
      }}
    >
      <div style={{ width: "90%", margin: "auto" }}> {/* Adjusted width for responsiveness */}
        <Grid container spacing={2} alignItems="center" direction="rtl">
          <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', sm: 'flex-end' }, flexDirection: { xs: 'column', sm: 'row' } }}>
            <Typography variant="h6" sx={{ fontFamily: '"MO_Nawel", sans-serif', marginRight: '8px', fontSize: { xs: "30px", sm: "50px" }, fontWeight: "500", textAlign: { xs: 'center', sm: 'right' } }}>
              اشـتـرك بالنـشرة البـريـديـة
            </Typography>
            <EmailIcon sx={{ color: 'white', fontSize: { xs: "40px", sm: "50px" }, mb: { xs: 1, sm: 0 } }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" sx={{ fontFamily: '"MO_Nawel", sans-serif', textAlign: 'right', fontSize: { xs: "30px", sm: "50px" }, fontWeight: "600" }}>
              تـواصـل مـعـنا
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ marginTop: '20px', direction: "rtl" }}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              placeholder="الاسم"
              variant="filled"
              sx={{
                backgroundColor: '#1C1F33',
                borderRadius: '5px',
                '& .MuiFilledInput-root': {
                  color: 'white',
                  borderRadius: '5px',
                  height: { xs: '50px', sm: '60px' }, // Responsive height
                  fontFamily: '"MO_Nawel", sans-serif',
                  textAlign: 'right',
                },
              }}
              InputProps={{
                disableUnderline: true,
                style: { color: 'white' },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              placeholder="رقم الجوال"
              variant="filled"
              sx={{
                backgroundColor: '#1C1F33',
                borderRadius: '5px',
                '& .MuiFilledInput-root': {
                  color: 'white',
                  borderRadius: '5px',
                  height: { xs: '50px', sm: '60px' }, // Responsive height
                  fontFamily: '"MO_Nawel", sans-serif',
                  textAlign: 'right',
                },
              }}
              InputProps={{
                disableUnderline: true,
                style: { color: 'white' },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              placeholder="البريد الإلكتروني"
              variant="filled"
              sx={{
                backgroundColor: '#1C1F33',
                borderRadius: '5px',
                '& .MuiFilledInput-root': {
                  color: 'white',
                  borderRadius: '5px',
                  height: { xs: '50px', sm: '60px' }, // Responsive height
                  fontFamily: '"MO_Nawel", sans-serif',
                  textAlign: 'right',
                },
              }}
              InputProps={{
                disableUnderline: true,
                style: { color: 'white' },
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ marginTop: '20px', direction: "rtl" }}>
          <Grid item xs={12} sm={9}>
            <TextField
              fullWidth
              variant="filled"
              placeholder="رسالتك"
              sx={{
                backgroundColor: '#1C1F33',
                borderRadius: '5px',
                '& .MuiFilledInput-root': {
                  color: 'white',
                  borderRadius: '5px',
                  height: { xs: '50px', sm: '60px' }, // Responsive height
                  fontFamily: '"MO_Nawel", sans-serif',
                  textAlign: 'right',
                },
              }}
              InputProps={{
                disableUnderline: true,
                style: { color: 'white' },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: '#42aaa6',
                color: 'white',
                borderRadius: '10px',
                padding: '10px 0',
                fontFamily: '"MO_Nawel", sans-serif',
                height: { xs: '50px', sm: '60px' }, // Match height with the text fields
                fontSize: { xs: "30px", sm: "40px" },
                fontWeight: "400",
              }}
            >
              اشترك الان
            </Button>
          </Grid>
        </Grid>

        <Grid container sx={{ marginTop: '20px', direction: "rtl" }}>
          <Grid item xs={12} sm={3} sx={{ ml: 'auto' }}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: '#42aaa6',
                color: 'white',
                borderRadius: '10px',
                padding: '10px 0',
                fontFamily: '"MO_Nawel", sans-serif',
                height: { xs: '50px', sm: '60px' }, // Match height with the text fields
                fontSize: { xs: "30px", sm: "40px" },
                fontWeight: "400",
              }}
            >
              ارسال
            </Button>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default ContactFormNew;
