import React, { useContext, useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Box, Typography, Button } from '@mui/material';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { ThemeContext } from '../ThemeContext';
import { green } from '@mui/material/colors'; 

const API_URL = 'https://backendforerpsystem.zbooma.com/customers.php';

const sliderSettings = {
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
};

const OurCustomers = () => {
  const { themeMode } = useContext(ThemeContext);
  const isDarkMode = themeMode === 'dark';
  const [customers, setCustomers] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  // Fetch customers data from API
  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await fetch(API_URL);
        const data = await response.json();

        if (data && data.length > 0) {
          setCustomers(data); // Set customers for the slider
          setTitle(data[0].name); // Set title from the first customer
          setDescription(data[0].description); // Set description from the first customer
        }
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    };

    fetchCustomers();
  }, []);

  return (
    <Box sx={{ padding: { xs: '20px 10px', md: '30px 20px' }, textAlign: 'center', fontFamily: '"MO_Nawel", sans-serif', backgroundColor:'#30355b', color:"white" }}>
      {/* Render dynamic title and description */}
      <Typography variant="h2" sx={{ fontSize: { xs: '3em', md: '4em' }, marginBottom: '10px', fontFamily: '"MO_Nawel", sans-serif', fontWeight: '500' }}>
        {title} {/* Dynamic title */}
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '30px', fontWeight: '350', fontFamily: '"MO_Nawel", sans-serif', fontSize: { xs: '25px', md: '30px' } }}>
        {description} {/* Dynamic description */}
      </Typography>

      <Box sx={{ width: { xs: '80%', md: '80%' }, margin: '0 auto' }}>
        <Slider {...sliderSettings}>
          {customers.map((customer, index) => (
            <Box key={index} sx={{ padding: '10px' }}>
              <Box sx={{
                borderRadius: '10px',
                overflow: 'hidden',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                textAlign: 'center',
                backgroundColor: isDarkMode ? '#ffffff' : '#005177',
                color: isDarkMode ? '#000000' : '#ffffff',
                padding: '0px',
                marginRight: { xs: '20px', md: '20px' },
                height: { xs: '150px', md: '270px' },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  flexGrow: 1, 
                  padding: '10px' 
                }}>
                  <img 
                    src={`https://backendforerpsystem.zbooma.com/${customer.image_url}`} 
                    alt={`شريك ${index + 1}`} 
                    style={{ 
                      width: '100%', 
                      height: 'auto', 
                      objectFit: 'cover', 
                      borderRadius: '10px',
                      filter: isDarkMode ? 'invert(1)' : 'none' 
                    }} 
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px' }}>
        <Button variant="outlined" sx={{ 
          borderColor: isDarkMode ? '#ffffff' : '#ffffff', 
          color: isDarkMode ? '#ffffff' : '#ffffff',
          '&:hover': { 
            backgroundColor: isDarkMode ? '#ffffff' : '#005177', 
            color: isDarkMode ? '#005177' : 'white' 
          }, 
          fontFamily: 'Cairo, sans-serif' 
        }}>
          عنا
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: green[500],
            color: '#ffffff',
            '&:hover': {
              backgroundColor: green[700],
            },
            fontFamily: 'Cairo, sans-serif',
            display: 'flex',
            alignItems: 'center',
          }}
          href="https://wa.me/966553165725"
          target="_blank"
        >
          <WhatsAppIcon sx={{ marginRight: '8px' }} />
          اتصل بنا
        </Button>
      </Box>

      <style jsx global>{`
        @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;600;700&display=swap');
        body {
          font-family: 'Cairo', sans-serif;
        }
      `}</style>
    </Box>
  );
};

export default OurCustomers;
