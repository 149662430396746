import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Container,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { ThemeContext } from '../ThemeContext';
import logo from '../../images/Asset 3.png';
import logo2 from '../../images/ارتقي 2.svg';

const pages = ['الرئيسية', 'تسجيل', 'أعمالنا', 'دخول', 'خدماتنا','المدونة'];
const settings = ['دخول المسئول'];

function NavBar() {
  const navigate = useNavigate();
  const { themeMode, toggleTheme } = React.useContext(ThemeContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const generalDataObject = JSON.parse(localStorage.getItem('generalDataObject'));
    if (generalDataObject && generalDataObject.token) {
      setIsLoggedIn(true);
      fetchNotifications();
    }
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await fetch('http://ertaqybackend.ertaqyagency-e.com/get_notifications.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: JSON.parse(localStorage.getItem('generalDataObject')).user_id }),
      });
      const result = await response.json();
      if (response.ok) {
        setNotifications(result.data);
      } else {
        console.error('Error fetching notifications:', result.message);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const navigateToPage = (page) => {
    switch (page) {
      case 'الرئيسية':
        navigate('/');
        break;
      case 'دخول':
        navigate('/login');
        break;
      case 'تسجيل':
        navigate('/register');
        break;
      case 'أعمالنا':
        navigate('/aboutus');
        break;
      case 'خدماتنا':
        navigate('/sevices');
        break;
      case 'المدونة':
        navigate('/madona');
        break;
      case 'اتصل بنا':
        navigate('/contactus');
        break;
      default:
        break;
    }
    
    setDrawerOpen(false); // Close the drawer after navigation
  };

  const drawerList = (
    <Box
      sx={{
        width: 250,
        maxHeight: '70vh', // Limit the height of the drawer to 70% of the viewport height
        overflowY: 'auto', // Allow scrolling if content overflows
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {pages.map((page) => (
          <ListItem button key={page} onClick={() => navigateToPage(page)}>
            <ListItemText
              primary={page}
              sx={{
                textAlign: 'center',
                fontSize: '1.3rem',
                fontFamily: '"Cairo", sans-serif',
                fontWeight: 'bold',
                color: themeMode === 'light' ? 'black' : 'white',
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: themeMode === 'light' ? 'white' : '#333',
          color: themeMode === 'light' ? 'black' : 'white',
          direction: 'rtl',
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
            <IconButton sx={{ ml: 1, color: themeMode === 'dark' ? 'white' : 'inherit' }} onClick={toggleTheme}>
              {themeMode === 'light' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>

            <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
              <img
                src={themeMode === 'light' ? logo : logo2}
                alt="Logo"
                style={{ height: '60px', width: '60px', cursor: 'pointer' }}
                onClick={() => navigate('/')}
              />
            </Box>

            <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
              <img
                src={themeMode === 'light' ? logo : logo2}
                alt="Logo"
                style={{ height: '102px', width: '80px', padding: '3px', cursor: 'pointer' }}
                onClick={() => navigate('/')}
              />
            </Box>

            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                color="inherit"
                sx={{ color: themeMode === 'light' ? 'black' : 'white' }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                sx={{
                  '& .MuiDrawer-paper': {
                    bgcolor: themeMode === 'light' ? 'white' : '#333',
                    color: themeMode === 'light' ? 'black' : 'white',
                  },
                }}
              >
                {drawerList}
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                  <IconButton href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" sx={{ color: themeMode === 'light' ? 'black' : 'white' }}>
                    <FacebookIcon />
                  </IconButton>
                  <IconButton href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" sx={{ color: themeMode === 'light' ? 'black' : 'white' }}>
                    <TwitterIcon />
                  </IconButton>
                  <IconButton href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" sx={{ color: themeMode === 'light' ? 'black' : 'white' }}>
                    <InstagramIcon />
                  </IconButton>
                </Box>
              </Drawer>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={() => navigateToPage(page)}
                  sx={{
                    my: 2,
                    mx: 1.5,
                    color: themeMode === 'light' ? 'black' : 'white',
                    display: 'block',
                    fontSize: '1.3rem', // Larger font size
                    fontFamily: 'Cairo, sans-serif',
                    fontWeight: 'bold', // Bold font
                  }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Link to="/admin/login" style={{ textDecoration: "none", color: themeMode === 'light' ? 'black' : 'white' }}>
                <Typography style={{ fontFamily: 'Cairo, sans-serif', fontWeight: 'bold', cursor: "pointer" }} textAlign="center">
                  دخول المسئول
                </Typography>
              </Link>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Add this Box to push down the content to avoid overlap with the fixed navbar */}
      <Box sx={{ height: '70px' }} />
    </>
  );
}
export default NavBar;
