import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button, Typography, Box } from '@mui/material';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../Components/ThemeContext';

const ProfessionalRegister = () => {
  const { themeMode } = useContext(ThemeContext); // Access the theme mode
  const isDarkMode = themeMode === 'dark'; // Determine if dark mode is active

  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const response = await fetch('http://ertaqybackend.ertaqyagency-e.com/register_user.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (response.ok) {
        swal({
          title: "نجاح",
          text: "تم تسجيل المستخدم بنجاح",
          icon: "success",
          buttons: {
            login: {
              text: "دخول",
              value: "login",
            },
          },
        }).then((value) => {
          if (value === "login") {
            navigate('/login');
          }
        });
      } else {
        swal("خطأ", result.message || "حدث خطأ أثناء تسجيل المستخدم", "error");
      }
    } catch (error) {
      swal("خطأ", "حدث خطأ أثناء الاتصال بالخادم", "error");
    }
  };

  return (
    <div style={{ backgroundColor: isDarkMode ? 'black' : null }}>
      <NavBar />
      <Box
        sx={{
          fontFamily: 'Cairo, sans-serif',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px',
          maxWidth: '500px',
          margin: '50px auto',
          backgroundColor: isDarkMode ? '#2c2c2c' : '#f9f9f9', // Dark grey for dark mode
          color: isDarkMode ? '#e0e0e0' : '#000', // Light text for dark mode
          borderRadius: '10px',
          boxShadow: isDarkMode ? '0 4px 8px rgba(255, 255, 255, 0.2)' : '0 4px 8px rgba(0, 0, 0, 0.1)', // Dark mode shadow
          direction: 'rtl',
          '@media (max-width: 600px)': {
            padding: '10px',
            margin: '20px auto',
          }
        }}
      >
        <Typography
          style={{ fontFamily: "Cairo, sans-serif", color: isDarkMode ? '#e0e0e0' : '#000' }} // Updated dark mode text color
          variant="h4"
          component="h2"
          gutterBottom
        >
          تسجيل مستخدم جديد
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          {/* Username Field */}
          <TextField
            style={{ fontFamily: "Cairo, sans-serif" }}
            label="اسم المستخدم"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register('username', { required: 'هذا الحقل مطلوب' })}
            error={!!errors.username}
            helperText={errors.username ? errors.username.message : ''}
            InputLabelProps={{ style: { right: 30, left: 'auto', direction: 'rtl', fontFamily: 'Cairo, sans-serif', color: isDarkMode ? '#e0e0e0' : '#000' } }}
            inputProps={{ style: { textAlign: 'right', direction: 'rtl', fontFamily: 'Cairo, sans-serif', color: isDarkMode ? '#e0e0e0' : '#000' } }}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: isDarkMode ? '#3c3c3c' : '#fff', // Slightly lighter dark grey for input fields
                '& fieldset': {
                  borderColor: isDarkMode ? '#444' : '#ccc', // Dark mode input border
                },
              },
              '& .MuiFormLabel-root': {
                color: isDarkMode ? '#e0e0e0' : '#000', // Dark mode label color
              },
            }}
          />

          {/* Password Field */}
          <TextField
            style={{ fontFamily: "Cairo, sans-serif" }}
            label="كلمة المرور"
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            {...register('password', { required: 'هذا الحقل مطلوب' })}
            error={!!errors.password}
            helperText={errors.password ? errors.password.message : ''}
            InputLabelProps={{ style: { right: 30, left: 'auto', direction: 'rtl', fontFamily: 'Cairo, sans-serif', color: isDarkMode ? '#e0e0e0' : '#000' } }}
            inputProps={{ style: { textAlign: 'right', direction: 'rtl', fontFamily: 'Cairo, sans-serif', color: isDarkMode ? '#e0e0e0' : '#000' } }}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: isDarkMode ? '#3c3c3c' : '#fff', // Slightly lighter dark grey for input fields
                '& fieldset': {
                  borderColor: isDarkMode ? '#444' : '#ccc', // Dark mode input border
                },
              },
              '& .MuiFormLabel-root': {
                color: isDarkMode ? '#e0e0e0' : '#000', // Dark mode label color
              },
            }}
          />

          <TextField
            style={{ fontFamily: "Cairo, sans-serif" }}
            label="الاسم"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register('name', { required: 'هذا الحقل مطلوب' })}
            error={!!errors.name}
            helperText={errors.name ? errors.name.message : ''}
            InputLabelProps={{ style: { right: 30, left: 'auto', direction: 'rtl', fontFamily: 'Cairo, sans-serif', color: isDarkMode ? '#e0e0e0' : '#000' } }}
            inputProps={{ style: { textAlign: 'right', direction: 'rtl', fontFamily: 'Cairo, sans-serif', color: isDarkMode ? '#e0e0e0' : '#000' } }}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: isDarkMode ? '#3c3c3c' : '#fff', // Slightly lighter dark grey for input fields
                '& fieldset': {
                  borderColor: isDarkMode ? '#444' : '#ccc', // Dark mode input border
                },
              },
              '& .MuiFormLabel-root': {
                color: isDarkMode ? '#e0e0e0' : '#000', // Dark mode label color
              },
            }}
          />

          <TextField
            style={{ fontFamily: "Cairo, sans-serif" }}
            label="الهاتف"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register('phone', { required: 'هذا الحقل مطلوب' })}
            error={!!errors.phone}
            helperText={errors.phone ? errors.phone.message : ''}
            InputLabelProps={{ style: { right: 30, left: 'auto', direction: 'rtl', fontFamily: 'Cairo, sans-serif', color: isDarkMode ? '#e0e0e0' : '#000' } }}
            inputProps={{ style: { textAlign: 'right', direction: 'rtl', fontFamily: 'Cairo, sans-serif', color: isDarkMode ? '#e0e0e0' : '#000' } }}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: isDarkMode ? '#3c3c3c' : '#fff', // Slightly lighter dark grey for input fields
                '& fieldset': {
                  borderColor: isDarkMode ? '#444' : '#ccc', // Dark mode input border
                },
              },
              '& .MuiFormLabel-root': {
                color: isDarkMode ? '#e0e0e0' : '#000', // Dark mode label color
              },
            }}
          />

          <TextField
            style={{ fontFamily: "Cairo, sans-serif" }}
            label="البريد الإلكتروني"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register('email', { required: 'هذا الحقل مطلوب', pattern: { value: /^\S+@\S+$/i, message: 'البريد الإلكتروني غير صحيح' } })}
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ''}
            InputLabelProps={{ style: { right: 30, left: 'auto', direction: 'rtl', fontFamily: 'Cairo, sans-serif', color: isDarkMode ? '#e0e0e0' : '#000' } }}
            inputProps={{ style: { textAlign: 'right', direction: 'rtl', fontFamily: 'Cairo, sans-serif', color: isDarkMode ? '#e0e0e0' : '#000' } }}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: isDarkMode ? '#3c3c3c' : '#fff', // Slightly lighter dark grey for input fields
                '& fieldset': {
                  borderColor: isDarkMode ? '#444' : '#ccc', // Dark mode input border
                },
              },
              '& .MuiFormLabel-root': {
                color: isDarkMode ? '#e0e0e0' : '#000', // Dark mode label color
              },
            }}
          />

          <TextField
            style={{ fontFamily: "Cairo, sans-serif" }}
            label="رابط الموقع"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register('website')}
            InputLabelProps={{ style: { right: 30, left: 'auto', direction: 'rtl', fontFamily: 'Cairo, sans-serif', color: isDarkMode ? '#e0e0e0' : '#000' } }}
            inputProps={{ style: { textAlign: 'right', direction: 'rtl', fontFamily: 'Cairo, sans-serif', color: isDarkMode ? '#e0e0e0' : '#000' } }}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: isDarkMode ? '#3c3c3c' : '#fff', // Slightly lighter dark grey for input fields
                '& fieldset': {
                  borderColor: isDarkMode ? '#444' : '#ccc', // Dark mode input border
                },
              },
              '& .MuiFormLabel-root': {
                color: isDarkMode ? '#e0e0e0' : '#000', // Dark mode label color
              },
            }}
          />

          <TextField
            style={{ fontFamily: "Cairo, sans-serif" }}
            label="العنوان"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register('address', { required: 'هذا الحقل مطلوب' })}
            error={!!errors.address}
            helperText={errors.address ? errors.address.message : ''}
            InputLabelProps={{ style: { right: 30, left: 'auto', direction: 'rtl', fontFamily: 'Cairo, sans-serif', color: isDarkMode ? '#e0e0e0' : '#000' } }}
            inputProps={{ style: { textAlign: 'right', direction: 'rtl', fontFamily: 'Cairo, sans-serif', color: isDarkMode ? '#e0e0e0' : '#000' } }}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: isDarkMode ? '#3c3c3c' : '#fff', // Slightly lighter dark grey for input fields
                '& fieldset': {
                  borderColor: isDarkMode ? '#444' : '#ccc', // Dark mode input border
                },
              },
              '& .MuiFormLabel-root': {
                color: isDarkMode ? '#e0e0e0' : '#000', // Dark mode label color
              },
            }}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              marginTop: '20px',
              backgroundColor: isDarkMode ? '#333' : '#1976d2', // Dark mode button background
              color: '#fff',
              '&:hover': {
                backgroundColor: isDarkMode ? '#444' : '#1565c0', // Dark mode button hover
              },
            }}
          >
            تسجيل
          </Button>
        </form>
      </Box>
      <Footer />
    </div>
  );
};

export default ProfessionalRegister;
