import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button, Card, CardMedia, CardContent, Typography, Grid, Container,
  CircularProgress, Box, TextField, IconButton
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';

const ProfessionalSectionManagement = () => {
  const [sectionData, setSectionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://backendforerpsystem.zbooma.com/professional_section.php');
        if (response.data.success) {
          const data = response.data.data[0];
          setSectionData(data);
          setFormData({
            ...data,
            cards: data.cards || [],  // Ensure cards is an array
          });
        } else {
          setError('Failed to fetch data.');
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Failed to fetch data!',
          });
        }
      } catch (err) {
        setError('Error fetching data from the server.');
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error fetching data from the server.',
        });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCardChange = (index, field, value) => {
    const updatedCards = formData.cards.map((card, i) =>
      i === index ? { ...card, [field]: value } : card
    );
    setFormData({ ...formData, cards: updatedCards });
  };

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    const updatedCards = formData.cards.map((card, i) =>
      i === index ? { ...card, imageFile: file } : card
    );
    setFormData({ ...formData, cards: updatedCards });
  };

  const handleDeleteCard = (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedCards = formData.cards.filter((_, i) => i !== index);
        setFormData({ ...formData, cards: updatedCards });
        Swal.fire(
          'Deleted!',
          'The card has been deleted.',
          'success'
        );
      }
    });
  };

  const handleSubmit = async () => {
    const updateFormData = new FormData();

    // Append the main text data
    updateFormData.append('main_title', formData.main_title);
    updateFormData.append('subtitle', formData.subtitle);
    updateFormData.append('button_text', formData.button_text);

    // Append card data as JSON
    updateFormData.append('cards', JSON.stringify(formData.cards));

    // Append card images if they are changed
    formData.cards.forEach((card, index) => {
      if (card.imageFile) {
        updateFormData.append(`card_images[${index}]`, card.imageFile); // Attach the card image files
      }
    });

    try {
      const response = await axios.post(
        'https://backendforerpsystem.zbooma.com/update_professional_section.php', // Using POST instead of PUT
        updateFormData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );

      if (response.data.success) {
        setSectionData({ ...sectionData, ...formData });
        setIsEditing(false);  // Exit edit mode
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Data updated successfully!',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: 'Failed to update data.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error updating data.',
      });
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6" color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Container>
      <Box mt={5} mb={5}>
        {isEditing ? (
          <>
            <TextField
              label="Main Title"
              name="main_title"
              value={formData.main_title}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Subtitle"
              name="subtitle"
              value={formData.subtitle}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Button Text"
              name="button_text"
              value={formData.button_text}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <Button variant="contained" color="success" onClick={handleSubmit}>
              Save
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h3" align="center" gutterBottom>
              {sectionData.main_title}
            </Typography>
            <Typography variant="body1" align="center" paragraph>
              {sectionData.subtitle}
            </Typography>
            <Box display="flex" justifyContent="center" mb={3}>
              <Button variant="contained" color="primary">
                {sectionData.button_text}
              </Button>
            </Box>
          </>
        )}
        <Box display="flex" justifyContent="center" mb={3}>
          <Button variant="contained" color="secondary" onClick={handleEditToggle}>
            {isEditing ? 'Cancel' : 'Edit'}
          </Button>
        </Box>
      </Box>

      <Grid container spacing={4}>
        {sectionData.cards.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            {isEditing ? (
              <>
                <TextField
                  label="Card Title"
                  value={formData.cards[index].card_title}
                  onChange={(e) => handleCardChange(index, 'card_title', e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Card Description"
                  value={formData.cards[index].card_description}
                  onChange={(e) => handleCardChange(index, 'card_description', e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <Box mt={2}>
                  <IconButton color="primary" aria-label="upload picture" component="label">
                    <input hidden accept="image/*" type="file" onChange={(e) => handleImageChange(e, index)} />
                    <CloudUploadIcon />
                  </IconButton>
                  <img
                    src={`https://backendforerpsystem.zbooma.com${card.card_image_url}`}
                    alt={card.card_title}
                    style={{ width: '100px', height: '100px', objectFit: 'cover', marginLeft: '10px' }}
                  />
                </Box>
                <IconButton color="error" aria-label="delete" onClick={() => handleDeleteCard(index)}>
                  <DeleteIcon />
                </IconButton>
              </>
            ) : (
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={`https://backendforerpsystem.zbooma.com${card.card_image_url}`}
                  alt={card.card_title}
                />
                <CardContent>
                  <Typography variant="h5" component="div">
                    {card.card_title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {card.card_description}
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ProfessionalSectionManagement;
