import React from "react";
import ContactForm from "../../Components/ContactUs/ContactUs";
import NavBar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
import { ThemeContext } from "../../Components/ThemeContext";
import { useContext } from "react";
const ContactPage = ()=>{
  const { themeMode } = useContext(ThemeContext); // Access the theme mode
  const isDarkMode = themeMode === 'dark'; // Determine if dark mode is active
    return(
        <div style={{backgroundColor:isDarkMode ? "#333333" : null}}>
          <NavBar/>
          <ContactForm/>
          <Footer/>
        </div>
    )
}

export default ContactPage