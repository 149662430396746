import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AppointmentBooking.css';
import Image1 from "../../../images/Ellipse 11.svg";
import Image2 from "../../../images/Ellipse 12.svg";

const AppointmentBooking = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    date: '',
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form data submission logic here
    console.log(formData);
    navigate('/login');
  };

  return (
    <div className="appointment-section">
      <div className="appointment-card">
        <h2>Appointment Booking</h2>
        <img src={Image1} alt="Decoration" className="small-item top-right" />
        <img src={Image2} alt="Decoration" className="small-item bottom-left" />
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="name"
              placeholder="NAME"
              value={formData.name}
              onChange={handleChange}
            />
            <input
              type="text"
              name="phone"
              placeholder="PHONE NUMBER"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              name="date"
              placeholder="DATE"
              value={formData.date}
              onChange={handleChange}
            />
            <input
              type="email"
              name="email"
              placeholder="EMAIL"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="send-button">SEND</button>
        </form>
      </div>
    </div>
  );
};

export default AppointmentBooking;
