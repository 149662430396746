import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Typography, Button } from "@mui/material";
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';
import image from "../../images/MaskGroup24.png"; // Ensure this path is correct
import NavBar from "../../Components/NavBar/NavBar";
import image1 from "../../images/servises/Group187.png";
import image2 from "../../images/servises/Group188.png";
import image3 from "../../images/servises/Group190.png";
import image4 from "../../images/servises/Group202.png";
import image5 from "../../images/servises/Group233.png";
import image6 from "../../images/servises/Group235.png";
import image7 from "../../images/serviseertaqy/edartmtagrelctronia.png";
import image8 from "../../images/serviseertaqy/graphicdesign.png";
import image9 from "../../images/serviseertaqy/motiongraphic.png";
import image10 from "../../images/serviseertaqy/tsmemmapplicationmopile.png";
import image11 from "../../images/serviseertaqy/tsmemmoaq3elctronoia.png";
import CommonQuestions from "../../Components/CommoneQuestions/CommoneQuestions";
import Footer from "../../Components/Footer/Footer";

const Services = () => {
  const navigate = useNavigate();
  const [inViewRef, inView] = useInView({ triggerOnce: true });
  const [startCount, setStartCount] = useState(false);

  const handleCardClick = (serviceName) => {
    navigate(`/service-detail/${serviceName}`);
  };

  const services = [
    { img: image3, text: "تصميم هوية بصرية" },
    { img: image9, text: "موشن جرافيك" },
    { img: image8, text: "جرافيك ديزاين" },
    { img: image2, text: "إدارة حسابات التواصل الاجتماعي" },
    { img: image7, text: "إدارة المتاجر الإلكترونية" },
    { img: image4, text: "إعلانات جوجل" },
    { img: image11, text: "إدارة الحملات الإعلانية" },
    { img: image1, text: "تحسين محركات البحث SEO" },
    { img: image10, text: "تصميم تطبيقات الجوال" },
    { img: image6, text: "تصميم مواقع الكترونية" }
  ];

  // Start counting when the section comes into view
  if (inView && !startCount) {
    setStartCount(true);
  }

  return (
    <div>
      <NavBar />
      <Box
        sx={{
          height: { xs: '40vh', md: '65vh' },
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          boxShadow: 'inset 0 0 0 1000px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: 'white',
            fontSize: { xs: '50px', md: '180px' },
            fontWeight: '500',
            textAlign: 'center',
            fontFamily: '"MO_Nawel", sans-serif',
          }}
        >
          خـدمـاتنا
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor:"#30355b",
          padding: { xs: '30px 0', md: '50px 0' },
          textAlign: 'center',
          color: 'white',
          fontFamily: '"MO_Nawel", sans-serif',
          transform: { md: 'scale(0.9)', lg: 'none' }, // Scaling to 90% for medium-sized screens
          transformOrigin: 'top center', // Ensures scaling is centered
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '30px', md: '60px' },
            marginBottom: { xs: '20px', md: '40px' },
            marginTop: { xs: '0', md: '-30px' },
            fontFamily: '"MO_Nawel", sans-serif',
          }}
        >
          نحن نؤمن ببناء علامات تجارية قوية
          <br />
          واستراتيجيات متكاملة
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
            },
            gap: '20px',
            margin: '0 auto',
            padding: { xs: '20px', md: '50px' },
          }}
        >
          {services.map((service, index) => (
            <Box
              key={index}
              onClick={() => handleCardClick(service.text)}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                padding: '20px',
                borderRadius: '15px',
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                height: 'auto',
                cursor: 'pointer',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              <img
                src={service.img}
                alt={service.text}
                style={{
                  width: '100%',
                  maxWidth: '250px',
                  height: 'auto',
                  marginBottom: '20px',
                }}
              />
              <Typography variant="h5" sx={{ fontSize: { xs: '18px', md: '28px' } }}>
                {service.text}
              </Typography>
            </Box>
          ))}
        </Box>

        {/* Buttons Section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
            marginTop: '30px',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Button
            sx={{
              backgroundColor: '#00bfa5',
              color: 'white',
              padding: { xs: '10px 20px', md: '10px 30px' },
              fontSize: { xs: '20px', md: '30px' },
              borderRadius: '5px',
              cursor: 'pointer',
              fontFamily: '"MO_Nawel", sans-serif',
              '&:hover': {
                backgroundColor: '#009b8a',
              },
            }}
          >
            تواصل معنا
          </Button>
          <Button
            sx={{
              backgroundColor: 'transparent',
              color: 'white',
              border: '2px solid white',
              padding: { xs: '10px 20px', md: '10px 30px' },
              fontSize: { xs: '20px', md: '30px' },
              borderRadius: '5px',
              cursor: 'pointer',
              fontFamily: '"MO_Nawel", sans-serif',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
              },
            }}
          >
            من نحن
          </Button>
        </Box>
      </Box>

      {/* Stats Section */}
      <Box
        ref={inViewRef}
        sx={{
          padding: '20px',
          marginTop: { xs: '100px', md: '0' },
        }}
      >
        <Grid container spacing={2} justifyContent="flex-end" sx={{ marginTop: '30px' }}>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                backgroundColor: '#242636',
                borderRadius: '15px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                minHeight: '200px',
                textAlign: 'center',
              }}
            >
              <Typography variant="h2" sx={{ fontFamily: '"MO_Nawel", sans-serif', fontWeight: 'bold', fontSize: { xs: '30px', md: '48px' } }}>
                {startCount && <CountUp start={0} end={100} duration={6} />}+
              </Typography>
              <Typography variant="h6" sx={{ fontFamily: '"MO_Nawel", sans-serif', marginTop: '10px', fontSize: { xs: '16px', md: '24px' } }}>
                عميل حالي
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                backgroundColor: '#242636',
                borderRadius: '15px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                minHeight: '200px',
                textAlign: 'center',
              }}
            >
              <Typography variant="h2" sx={{ fontFamily: '"MO_Nawel", sans-serif', fontWeight: 'bold', fontSize: { xs: '30px', md: '48px' } }}>
                {startCount && <CountUp start={0} end={200} duration={6} />}+
              </Typography>
              <Typography variant="h6" sx={{ fontFamily: '"MO_Nawel", sans-serif', marginTop: '10px', fontSize: { xs: '16px', md: '24px' } }}>
                مشروع مكتمل
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                backgroundColor: '#242636',
                borderRadius: '15px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                minHeight: '200px',
                textAlign: 'center',
              }}
            >
              <Typography variant="h2" sx={{ fontFamily: '"MO_Nawel", sans-serif', fontWeight: 'bold', fontSize: { xs: '30px', md: '48px' } }}>
                {startCount && <CountUp start={0} end={50} duration={6} />}+
              </Typography>
              <Typography variant="h6" sx={{ fontFamily: '"MO_Nawel", sans-serif', marginTop: '10px', fontSize: { xs: '16px', md: '24px' } }}>
                فريق عمل
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                backgroundColor: '#242636',
                borderRadius: '15px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                minHeight: '200px',
                textAlign: 'center',
              }}
            >
              <Typography variant="h2" sx={{ fontFamily: '"MO_Nawel", sans-serif', fontWeight: 'bold', fontSize: { xs: '30px', md: '48px' } }}>
                {startCount && <CountUp start={0} end={10} duration={6} />}+
              </Typography>
              <Typography variant="h6" sx={{ fontFamily: '"MO_Nawel", sans-serif', marginTop: '10px', fontSize: { xs: '16px', md: '24px' } }}>
                سنوات الخبرة
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Common Questions Section */}
      <CommonQuestions />
      <Footer />
    </div>
  );
};

export default Services;
