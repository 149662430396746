import React from "react";
import "./Section2.css";
import SliderComponent from "../SliderServices/SliderServices";
import AboutSection from "../Moassess/Moassess";
import VideoSlider from "../A3malanaSlider/A3malanaSlider";
import LogosSection from "../LogosSection/LogosSection";
import StatsSection from "../StatsSection/StatsSection";
import PartnerSlider from "../SlidersOurPartners/SlidersOurPartners";
import CardSlider from "../TracksSlidersAfterContactUs/TracksSlidersAfterContactUs";
import OurCustomers from "../OurCustomers/OurCustomers";
import OurServices from "../OurServices/OurServices";

const Section2 = () => {
  return (
    <div style={{ backgroundColor: "#30355b" }}>
      <div className="div222">
        <SliderComponent />
        <CardSlider />
        <AboutSection />
        <VideoSlider />
        <PartnerSlider />
        <OurCustomers />
        <LogosSection />
        <OurServices />
        <StatsSection />
      </div>
    </div>
  );
};

export default Section2;
