import React, { useEffect, useState } from 'react';

import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  CircularProgress,
  Card,
  CardContent,
  Grid
} from '@mui/material';
import { Visibility, Delete, Edit } from '@mui/icons-material';
import { CheckCircle, HourglassEmpty, PlayCircle } from '@mui/icons-material';
import swal from 'sweetalert';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
const defaultSteps = [
  { id: null, step_name: 'الاكونت', status: 'pending', notes: '' },
  { id: null, step_name: 'الانشاء', status: 'pending', notes: '' },
  { id: null, step_name: 'الجرافيك', status: 'pending', notes: '' },
  { id: null, step_name: 'البرمجة', status: 'pending', notes: '' }
];

const defaultMeetingTimes = {
  السبت: [],
  الأحد: [],
  الاثنين: [],
  الثلاثاء: [],
  الأربعاء: [],
  الخميس: []
};


const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [projectSteps, setProjectSteps] = useState(defaultSteps);
  const [meetingTimes, setMeetingTimes] = useState(defaultMeetingTimes);
  const [newMeetingTime, setNewMeetingTime] = useState({ day: 'السبت', start_time: '', end_time: '' });
  const [file, setFile] = useState(null);
  const [contactData, setContactData] = useState([]);
  const [newAdmin, setNewAdmin] = useState({ username: '', password: '' });
  const [admins, setAdmins] = useState([]);
  const [editAdmin, setEditAdmin] = useState(null);
  const location = useLocation();
  const username = new URLSearchParams(location.search).get('username');

  useEffect(() => {
    fetchUsers();
    fetchMeetingTimes();
    fetchContactData();
    fetchAdmins();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch('http://ertaqybackend.ertaqyagency-e.com/get_all_users.php', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const result = await response.json();
      if (response.ok) {
        setUsers(result.data);
      } else {
        console.error('Error fetching users data:', result.message);
      }
    } catch (error) {
      console.error('Error fetching users data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMeetingTimes = async () => {
    try {
      const response = await fetch('http://ertaqybackend.ertaqyagency-e.com/get_meeting_times.php', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const result = await response.json();
      if (response.ok) {
        setMeetingTimes(result.data);
      } else {
        console.error('Error fetching meeting times:', result.message);
      }
    } catch (error) {
      console.error('Error fetching meeting times:', error);
    }
  };

  const fetchContactData = async () => {
    try {
      const response = await fetch('https://ertaqybackend.ertaqyagency-e.com/get_contact_form_data.php', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const result = await response.json();
      if (response.ok) {
        setContactData(result.data);
      } else {
        console.error('Error fetching contact data:', result.message);
      }
    } catch (error) {
      console.error('Error fetching contact data:', error);
    }
  };

  const fetchAdmins = async () => {
    try {
      const response = await fetch('http://ertaqybackend.ertaqyagency-e.com/admin_operations.php');
      const result = await response.json();
      if (response.ok) {
        setAdmins(result.data);
      } else {
        console.error('Error fetching admins:', result.message);
      }
    } catch (error) {
      console.error('Error fetching admins:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClickOpen = async (user) => {
    setSelectedUser(user);
    setDialogOpen(true);
    setDialogLoading(true);
    try {
      const response = await fetch(`http://ertaqybackend.ertaqyagency-e.com/get_project_steps.php?user_id=${user.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const result = await response.json();
      if (response.ok) {
        const stepsData = result.data.length > 0 ? result.data.map(step => ({
          id: step.id,
          step_name: step.step_name,
          status: step.status || 'pending',
          notes: step.comment || ''
        })) : defaultSteps;
        setProjectSteps(stepsData);
      } else {
        console.error('Error fetching project steps:', result.message);
        setProjectSteps(defaultSteps);
      }
    } catch (error) {
      console.error('Error fetching project steps:', error);
      setProjectSteps(defaultSteps);
    } finally {
      setDialogLoading(false);
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedUser(null);
    setProjectSteps(defaultSteps);
  };

  const handleStepStatusChange = (event, stepId) => {
    const updatedSteps = projectSteps.map(step =>
      step.id === stepId || step.step_name === stepId ? { ...step, status: event.target.value } : step
    );
    setProjectSteps(updatedSteps);
  };

  const handleStepNotesChange = (event, stepId) => {
    const updatedSteps = projectSteps.map(step =>
      step.id === stepId || step.step_name === stepId ? { ...step, notes: event.target.value } : step
    );
    setProjectSteps(updatedSteps);
  };

  const postNotification = async (message) => {
    try {
      await fetch('http://ertaqybackend.ertaqyagency-e.com/post_notification.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message, user_id: selectedUser.id }),
      });
    } catch (error) {
      console.error('Error posting notification:', error);
    }
  };

  const handleStepSubmit = async (stepId) => {
    const step = projectSteps.find(s => s.id === stepId || s.step_name === stepId);
    const payload = {
      step_id: step.id || null,
      user_id: selectedUser.id,
      status: step.status,
      notes: step.notes,
      step_name: step.step_name
    };

    try {
      const response = await fetch(step.id ? 'http://ertaqybackend.ertaqyagency-e.com/update_project_step.php' : 'http://ertaqybackend.ertaqyagency-e.com/add_project_step.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();
      if (response.ok) {
        if (!step.id) {
          step.id = result.step_id;
        }
        swal("نجاح", "تم تحديث الخطوة بنجاح", "success");
        postNotification(`تم تحديث خطوة ${step.step_name}`);
      } else {
        swal("خطأ", result.message || "حدث خطأ أثناء تحديث الخطوة", "error");
        console.error('Error updating project step:', result.message);
      }
    } catch (error) {
      swal("خطأ", "حدث خطأ أثناء الاتصال بالخادم", "error");
      console.error('Error updating project step:', error);
    }
  };

  const handleAddMeetingTime = async () => {
    const { day, start_time, end_time } = newMeetingTime;
    if (!start_time || !end_time) return swal("خطأ", "الرجاء إدخال وقت بداية ونهاية صحيحين", "error");

    try {
      const response = await fetch('http://ertaqybackend.ertaqyagency-e.com/add_meeting_time.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ day, start_time, end_time }),
      });

      const result = await response.json();
      if (response.ok) {
        setMeetingTimes((prev) => ({
          ...prev,
          [day]: [...prev[day], { start_time, end_time, is_booked: null }]
        }));
        setNewMeetingTime({ day: 'السبت', start_time: '', end_time: '' });
        swal("نجاح", "تمت إضافة الوقت بنجاح", "success");
      } else {
        swal("خطأ", result.message || "حدث خطأ أثناء إضافة الوقت", "error");
        console.error('Error adding meeting time:', result.message);
      }
    } catch (error) {
      swal("خطأ", "حدث خطأ أثناء الاتصال بالخادم", "error");
      console.error('Error adding meeting time:', error);
    }
  };

  const handleSendWhatsApp = (phoneNumber, message) => {
    const encodedMessage = encodeURIComponent(message);
    const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(url, '_blank');
  };

  const HandleLogout = () => {
    localStorage.removeItem('adminToken');
    navigate('/admin/login');
  };
  const HandleChnage = () => {
    navigate('/changeContent');
  };
  
  const navigate = useNavigate();

  const handleDeleteMeetingTimes = async () => {
    swal({
      title: "هل أنت متأكد؟",
      text: "سيتم حذف جميع المواعيد لهذا الأسبوع. هل تريد المتابعة؟",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await fetch('http://ertaqybackend.ertaqyagency-e.com/delete_meeting_times.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
          });
  
          const result = await response.json();
          if (response.ok) {
            setMeetingTimes(defaultMeetingTimes);
            swal("نجاح", "تم حذف جميع المواعيد للأسبوع", "success");
          } else {
            swal("خطأ", result.message || "حدث خطأ أثناء حذف المواعيد", "error");
            console.error('Error deleting meeting times:', result.message);
          }
        } catch (error) {
          swal("خطأ", "حدث خطأ أثناء الاتصال بالخادم", "error");
          console.error('Error deleting meeting times:', error);
        }
      } else {
        swal("تم الإلغاء", "لم يتم حذف المواعيد", "info");
      }
    });
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileSubmit = async () => {
    if (!file) return swal("خطأ", "الرجاء اختيار ملف", "error");

    const formData = new FormData();
    formData.append('file', file);
    formData.append('user_id', selectedUser.id);

    try {
      const response = await fetch('http://ertaqybackend.ertaqyagency-e.com/upload_contract.php', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (response.ok) {
        swal("نجاح", "تم رفع الملف بنجاح", "success");
        postNotification("تم رفع عقد جديد");
      } else {
        swal("خطأ", result.message || "حدث خطأ أثناء رفع الملف", "error");
        console.error('Error uploading file:', result.message);
      }
    } catch (error) {
      swal("خطأ", "حدث خطأ أثناء الاتصال بالخادم", "error");
      console.error('Error uploading file:', error);
    }
  };

  const handleAdminChange = (event) => {
    const { name, value } = event.target;
    setNewAdmin((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditAdminChange = (event) => {
    const { name, value } = event.target;
    setEditAdmin((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddAdmin = async () => {
    if (!newAdmin.username || !newAdmin.password) {
      return swal("خطأ", "الرجاء إدخال اسم المستخدم وكلمة المرور", "error");
    }

    try {
      const response = await fetch('http://ertaqybackend.ertaqyagency-e.com/admin_operations.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...newAdmin, action: 'add' }),
      });

      const result = await response.json();
      if (response.ok) {
        swal("نجاح", "تم إضافة المسؤول بنجاح", "success");
        setNewAdmin({ username: '', password: '' });
        fetchAdmins();
      } else {
        swal("خطأ", result.message || "حدث خطأ أثناء إضافة المسؤول", "error");
        console.error('Error adding admin:', result.message);
      }
    } catch (error) {
      swal("خطأ", "حدث خطأ أثناء الاتصال بالخادم", "error");
      console.error('Error adding admin:', error);
    }
  };

  const handleDeleteAdmin = async (adminId) => {
    swal({
      title: "هل أنت متأكد؟",
      text: "سيتم حذف المسؤول نهائيًا. هل تريد المتابعة؟",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await fetch(`http://ertaqybackend.ertaqyagency-e.com/admin_operations.php?id=${adminId}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
          });
  
          const result = await response.json();
          if (response.ok) {
            swal("نجاح", "تم حذف المسؤول بنجاح", "success");
            fetchAdmins();
          } else {
            swal("خطأ", result.message || "حدث خطأ أثناء حذف المسؤول", "error");
            console.error('Error deleting admin:', result.message);
          }
        } catch (error) {
          swal("خطأ", "حدث خطأ أثناء الاتصال بالخادم", "error");
          console.error('Error deleting admin:', error);
        }
      } else {
        swal("تم الإلغاء", "لم يتم حذف المسؤول", "info");
      }
    });
  };
  
  const handleEditAdmin = async () => {
    if (!editAdmin.username || !editAdmin.password) {
      return swal("خطأ", "الرجاء إدخال اسم المستخدم وكلمة المرور", "error");
    }

    try {
      const response = await fetch('http://ertaqybackend.ertaqyagency-e.com/admin_operations.php', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editAdmin),
      });

      const result = await response.json();
      if (response.ok) {
        swal("نجاح", "تم تحديث المسؤول بنجاح", "success");
        setEditAdmin(null);
        fetchAdmins();
      } else {
        swal("خطأ", result.message || "حدث خطأ أثناء تحديث المسؤول", "error");
        console.error('Error updating admin:', result.message);
      }
    } catch (error) {
      swal("خطأ", "حدث خطأ أثناء الاتصال بالخادم", "error");
      console.error('Error updating admin:', error);
    }
  };

  const handleDeleteContact = async (id) => {
    swal({
      title: "هل أنت متأكد؟",
      text: "سيتم حذف الاستفسار نهائيًا. هل تريد المتابعة؟",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await fetch('https://ertaqybackend.ertaqyagency-e.com/get_contact_form_data.php', {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id }),
          });

          const result = await response.json();
          if (response.ok) {
            swal("نجاح", "تم حذف الاستفسار بنجاح", "success");
            setContactData(contactData.filter(contact => contact.id !== id));
          } else {
            swal("خطأ", result.message || "حدث خطأ أثناء حذف الاستفسار", "error");
            console.error('Error deleting contact:', result.message);
          }
        } catch (error) {
          swal("خطأ", "حدث خطأ أثناء الاتصال بالخادم", "error");
          console.error('Error deleting contact:', error);
        }
      } else {
        swal("تم الإلغاء", "لم يتم حذف الاستفسار", "info");
      }
    });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  return (
    <>
      <NavBar />
      <div style={{
  width:"100%",
  textAlign: "right", 
  display: "flex", 
  flexDirection: "row", 
  direction: "rtl", 
  fontFamily: "'Cairo', sans-serif",
  justifyContent:"space-evenly",
  marginTop:"20px"
}}>
  <h2 style={{
    textAlign: "right", 
    marginTop: "30px", 
    marginRight: "20px", 
    cursor: "pointer", 
    fontSize: "1.5rem", 
    color: "#333", 
    transition: "color 0.3s ease",
    padding: "10px",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)"
  }} 
  onMouseEnter={e => e.target.style.color = '#007bff'}
  onMouseLeave={e => e.target.style.color = '#333'}
  onClick={HandleLogout}>
    تسجيل الخروج
  </h2>

  <h2 style={{
    textAlign: "right", 
    marginTop: "30px", 
    marginRight: "20px", 
    cursor: "pointer", 
    fontSize: "1.5rem", 
    color: "#333", 
    transition: "color 0.3s ease",
    padding: "10px",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)"
  }} 
  onMouseEnter={e => e.target.style.color = '#007bff'}
  onMouseLeave={e => e.target.style.color = '#333'}
  onClick={HandleChnage}>
    تغير المحتوى
  </h2>
</div>

      <Box
        sx={{
          fontFamily: 'Cairo, sans-serif',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px',
          maxWidth: '1200px',
          margin: '50px auto',
          backgroundColor: '#f9f9f9',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          direction: 'rtl',
        }}
      >
        <Typography variant="h4" sx={{ fontFamily: 'Cairo, sans-serif', marginBottom: '20px' }}>
          لوحة تحكم المسؤول
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: 'Cairo, sans-serif', marginBottom: '20px' }}>
          مرحباً بكم في لوحة تحكم المسؤول.
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>ID</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>اسم المستخدم</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>البريد الإلكتروني</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>الاسم</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>الهاتف</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>رابط الموقع</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>العنوان</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>عرض</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>{user.id}</TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>{user.username}</TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>{user.email}</TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>{user.name}</TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>{user.phone}</TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>{user.website}</TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>{user.address}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => handleClickOpen(user)}>
                      <Visibility />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {username === 'Ertaqyagency' && (
          <Box
            sx={{
              marginTop: '20px',
              padding: '20px',
              backgroundColor: '#fff',
              borderRadius: '10px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              width: '100%',
            }}
          >
            <Typography variant="h6" sx={{ fontFamily: 'Cairo, sans-serif', marginBottom: '10px' }}>
              إضافة مسؤول جديد
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <TextField
                label="اسم المستخدم"
                variant="outlined"
                name="username"
                value={newAdmin.username}
                onChange={handleAdminChange}
                sx={{ fontFamily: 'Cairo, sans-serif' }}
                fullWidth
              />
              <TextField
                label="كلمة المرور"
                type="password"
                variant="outlined"
                name="password"
                value={newAdmin.password}
                onChange={handleAdminChange}
                sx={{ fontFamily: 'Cairo, sans-serif' }}
                fullWidth
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddAdmin}
                sx={{ fontFamily: 'Cairo, sans-serif' }}
              >
                إضافة
              </Button>
            </Box>
            <Typography variant="h6" sx={{ fontFamily: 'Cairo, sans-serif', marginTop: '20px' }}>
              المسؤولون الحاليون
            </Typography>
            <TableContainer component={Paper} sx={{ marginTop: '10px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>ID</TableCell>
                    <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>اسم المستخدم</TableCell>
                    <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>تحكم</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {admins.map((admin) => (
                    <TableRow key={admin.id}>
                      <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>{admin.id}</TableCell>
                      <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>{admin.username}</TableCell>
                      <TableCell align="right">
                        <IconButton onClick={() => setEditAdmin(admin)}>
                          <Edit />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteAdmin(admin.id)}>
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        <Typography variant="h4" sx={{ fontFamily: 'Cairo, sans-serif', marginTop: '40px', marginBottom: '20px' }}>
          المواعيد المتاحة خلال الأسبوع
        </Typography>
        <Grid container spacing={4}>
          {Object.entries(meetingTimes).map(([day, times]) => (
            <Grid item xs={12} md={6} key={day}>
              <Card sx={{ minHeight: '150px', fontFamily: 'Cairo, sans-serif' }}>
                <CardContent>
                  <Typography variant="h6" sx={{ fontFamily: 'Cairo, sans-serif' }}>{day}</Typography>
                  <Box>
                    {times.map((time, index) => (
                      <Box key={index} sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                        <Typography sx={{ fontFamily: 'Cairo, sans-serif' }}>
                          {time.start_time} - {time.end_time} ({time.is_booked ? 'محجوز' : 'غير محجوز'})
                        </Typography>
                        {time.is_booked && time.user && (
                          <Box sx={{ ml: 2 }}>
                            <Typography variant="body2" sx={{ fontFamily: 'Cairo, sans-serif', color: 'gray' }}>
                              <strong>المستخدم:</strong> {time.user.name}
                            </Typography>
                            <Typography variant="body2" sx={{ fontFamily: 'Cairo, sans-serif', color: 'gray' }}>
                              <strong>البريد الإلكتروني:</strong> {time.user.email}
                            </Typography>
                            <Typography variant="body2" sx={{ fontFamily: 'Cairo, sans-serif', color: 'gray' }}>
                              <strong>الهاتف:</strong> {time.user.phone}
                            </Typography>
                            <Typography variant="body2" sx={{ fontFamily: 'Cairo, sans-serif', color: 'gray' }}>
                              <strong>العنوان:</strong> {time.user.address}
                            </Typography>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleSendWhatsApp(time.user.phone, `لقد استلمنا معاد الحجز الخاص بك في يوم ${day} من ${time.start_time} إلى ${time.end_time} وسيتم التحدث إليك في المعاد المحدد.`)}
                              sx={{ fontFamily: 'Cairo, sans-serif', mt: 1 }}
                            >
                              إرسال تأكيد عبر WhatsApp
                            </Button>
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ marginTop: '20px', width: '100%', display: 'flex', justifyContent: 'center' }}>
          <FormControl sx={{ marginRight: '10px', minWidth: 120, fontFamily: 'Cairo, sans-serif' }}>
            <InputLabel sx={{ fontFamily: 'Cairo, sans-serif' }}>اليوم</InputLabel>
            <Select
              value={newMeetingTime.day}
              onChange={(e) => setNewMeetingTime((prev) => ({ ...prev, day: e.target.value }))}
              label="اليوم"
              sx={{ fontFamily: 'Cairo, sans-serif' }}
            >
              {Object.keys(defaultMeetingTimes).map((day) => (
                <MenuItem key={day} value={day} sx={{ fontFamily: 'Cairo, sans-serif' }}>{day}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="وقت البدء"
            type="time"
            value={newMeetingTime.start_time}
            onChange={(e) => setNewMeetingTime((prev) => ({ ...prev, start_time: e.target.value }))}
            InputLabelProps={{
              shrink: true,
              sx: { fontFamily: 'Cairo, sans-serif' }
            }}
            inputProps={{
              step: 300, // 5 minutes
              sx: { fontFamily: 'Cairo, sans-serif' }
            }}
            sx={{ marginRight: '10px', fontFamily: 'Cairo, sans-serif' }}
          />
          <TextField
            label="وقت الانتهاء"
            type="time"
            value={newMeetingTime.end_time}
            onChange={(e) => setNewMeetingTime((prev) => ({ ...prev, end_time: e.target.value }))}
            InputLabelProps={{
              shrink: true,
              sx: { fontFamily: 'Cairo, sans-serif' }
            }}
            inputProps={{
              step: 300, // 5 minutes
              sx: { fontFamily: 'Cairo, sans-serif' }
            }}
            sx={{ marginRight: '10px', fontFamily: 'Cairo, sans-serif' }}
          />
          <Button variant="contained" color="primary" onClick={handleAddMeetingTime} sx={{ fontFamily: 'Cairo, sans-serif' }}>
            إضافة وقت
          </Button>
        </Box>
        <Box sx={{ marginTop: '20px', width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" color="secondary" onClick={handleDeleteMeetingTimes} sx={{ fontFamily: 'Cairo, sans-serif' }}>
            حذف جميع المواعيد للأسبوع
          </Button>
        </Box>
        <Typography variant="h4" sx={{ fontFamily: 'Cairo, sans-serif', marginTop: '40px', marginBottom: '20px' }}>
          الاستفسارات القادمة من الزائرين
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>ID</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>الاسم</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>البريد الإلكتروني</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>الهاتف</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>الميزانية</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>رابط المتجر</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>المصدر</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>نظرة عامة</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>رد</TableCell>
                <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>حذف</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contactData.map((contact) => (
                <TableRow key={contact.id}>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>{contact.id}</TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>{contact.name}</TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>{contact.email}</TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>{contact.phone}</TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>{contact.budget}</TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>{contact.storeLink}</TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>{contact.source}</TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'Cairo, sans-serif' }}>{contact.overview}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSendWhatsApp(contact.phone, `مرحبًا ${contact.name}, شكرًا لاستفسارك. نود أن نناقش طلبك أكثر.`)}
                      sx={{ fontFamily: 'Cairo, sans-serif' }}
                    >
                      رد
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => handleDeleteContact(contact.id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Footer />
      <Dialog open={dialogOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle sx={{ fontFamily: 'Cairo, sans-serif' }}>تفاصيل المستخدم</DialogTitle>
        <DialogContent sx={{ fontFamily: 'Cairo, sans-serif', direction: 'rtl' }}>
          {dialogLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </Box>
          ) : (
            selectedUser && (
              <>
                <DialogContentText sx={{ fontFamily: 'Cairo, sans-serif' }}>
                  <strong>ID:</strong> {selectedUser.id}
                </DialogContentText>
                <DialogContentText sx={{ fontFamily: 'Cairo, sans-serif' }}>
                  <strong>اسم المستخدم:</strong> {selectedUser.username}
                </DialogContentText>
                <DialogContentText sx={{ fontFamily: 'Cairo, sans-serif' }}>
                  <strong>البريد الإلكتروني:</strong> {selectedUser.email}
                </DialogContentText>
                <DialogContentText sx={{ fontFamily: 'Cairo, sans-serif' }}>
                  <strong>الاسم:</strong> {selectedUser.name}
                </DialogContentText>
                <DialogContentText sx={{ fontFamily: 'Cairo, sans-serif' }}>
                  <strong>الهاتف:</strong> {selectedUser.phone}
                </DialogContentText>
                <DialogContentText sx={{ fontFamily: 'Cairo, sans-serif' }}>
                  <strong>رابط الموقع:</strong> {selectedUser.website}
                </DialogContentText>
                <DialogContentText sx={{ fontFamily: 'Cairo, sans-serif' }}>
                  <strong>العنوان:</strong> {selectedUser.address}
                </DialogContentText>
                <Typography variant="h6" sx={{ fontFamily: 'Cairo, sans-serif', marginTop: '20px' }}>
                  تفاصيل المشروع
                </Typography>
                {projectSteps.map((step) => (
                  <Box key={step.id || step.step_name} sx={{ marginBottom: '20px' }}>
                    {step.status && (
                      <Typography variant="body2" sx={{ fontFamily: 'Cairo, sans-serif', color: 'gray' }}>
                        حالة الخطوة: {step.status === 'completed' ? 'منتهي' : step.status === 'pending' ? 'قيد الانتظار' : 'جاري التنفيذ'}
                      </Typography>
                    )}
                    {step.notes && (
                      <Typography variant="body2" sx={{ fontFamily: 'Cairo, sans-serif', color: 'gray' }}>
                        ملاحظات: {step.notes}
                      </Typography>
                    )}
                    <Typography variant="h6" sx={{ fontFamily: 'Cairo, sans-serif', display: 'flex', alignItems: 'center' }}>
                      {step.step_name}
                      {step.status === 'completed' && <CheckCircle sx={{ marginLeft: '10px', color: 'green' }} />}
                      {step.status === 'pending' && <HourglassEmpty sx={{ marginLeft: '10px', color: 'orange' }} />}
                      {step.status === 'in-progress' && <PlayCircle sx={{ marginLeft: '10px', color: 'blue' }} />}
                    </Typography>
                    <FormControl fullWidth sx={{ marginTop: '10px' }}>
                      <InputLabel sx={{ fontFamily: 'Cairo, sans-serif' }}>الحالة</InputLabel>
                      <Select
                        value={step.status}
                        onChange={(e) => handleStepStatusChange(e, step.id || step.step_name)}
                        label="الحالة"
                        sx={{ fontFamily: 'Cairo, sans-serif' }}
                      >
                        <MenuItem value="completed" sx={{ fontFamily: 'Cairo, sans-serif' }}>منتهي</MenuItem>
                        <MenuItem value="pending" sx={{ fontFamily: 'Cairo, sans-serif' }}>قيد الانتظار</MenuItem>
                        <MenuItem value="in-progress" sx={{ fontFamily: 'Cairo, sans-serif' }}>جاري التنفيذ</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      label="ملاحظات"
                      multiline
                      rows={4}
                      fullWidth
                      variant="outlined"
                      value={step.notes}
                      onChange={(e) => handleStepNotesChange(e, step.id || step.step_name)}
                      sx={{ marginTop: '10px', fontFamily: 'Cairo, sans-serif' }}
                    />
                    <Button variant="contained" color="primary" onClick={() => handleStepSubmit(step.id || step.step_name)} sx={{ marginTop: '10px', fontFamily: 'Cairo, sans-serif' }}>
                      إرسال
                    </Button>
                  </Box>
                ))}
                <Typography variant="h6" sx={{ fontFamily: 'Cairo, sans-serif', marginTop: '20px' }}>
                  اضافة عقد يكون خاص بهذه المسخدم
                </Typography>
                <TextField
                  type="file"
                  onChange={handleFileChange}
                  sx={{ fontFamily: 'Cairo, sans-serif', marginTop: '10px' }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFileSubmit}
                  sx={{ marginTop: '10px', fontFamily: 'Cairo, sans-serif' }}
                >
                  رفع
                </Button>
              </>
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ fontFamily: 'Cairo, sans-serif' }}>إغلاق</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={Boolean(editAdmin)} onClose={() => setEditAdmin(null)} maxWidth="md" fullWidth>
        <DialogTitle sx={{ fontFamily: 'Cairo, sans-serif' }}>تعديل بيانات المسؤول</DialogTitle>
        <DialogContent sx={{ fontFamily: 'Cairo, sans-serif' }}>
          {editAdmin && (
            <>
              <TextField
                label="اسم المستخدم"
                variant="outlined"
                name="username"
                value={editAdmin.username}
                onChange={handleEditAdminChange}
                sx={{ fontFamily: 'Cairo, sans-serif', marginBottom: '10px' }}
                fullWidth
              />
              <TextField
                label="كلمة المرور"
                type="password"
                variant="outlined"
                name="password"
                value={editAdmin.password}
                onChange={handleEditAdminChange}
                sx={{ fontFamily: 'Cairo, sans-serif' }}
                fullWidth
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditAdmin(null)} sx={{ fontFamily: 'Cairo, sans-serif' }}>إلغاء</Button>
          <Button onClick={handleEditAdmin} sx={{ fontFamily: 'Cairo, sans-serif' }}>تحديث</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminDashboard;
