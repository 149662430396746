import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, TextField, Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Swal from 'sweetalert2';

const API_URL = 'https://backendforerpsystem.zbooma.com/get_stats_section.php';
const UPDATE_URL = 'https://backendforerpsystem.zbooma.com/update_stats_section.php';

const EditableStatsSection = () => {
  const [statsData, setStatsData] = useState({
    title: '',
    subtitle: '',
    background_image: '',
    cards: [],
  });

  const [isEditing, setIsEditing] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state

  useEffect(() => {
    axios.get(API_URL)
      .then((response) => {
        if (response.data.success) {
          setStatsData(response.data.data);
          setPreviewImage(`https://backendforerpsystem.zbooma.com/${response.data.data.background_image}`); // Set existing image
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStatsData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCardChange = (e, index) => {
    const { name, value } = e.target;
    const updatedCards = statsData.cards.map((card, i) =>
      i === index ? { ...card, [name]: value } : card
    );
    setStatsData(prevState => ({ ...prevState, cards: updatedCards }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const previewUrl = URL.createObjectURL(file); // Create preview URL
    setPreviewImage(previewUrl);
    setStatsData(prevState => ({ ...prevState, background_image: file }));
  };

  const handleSave = () => {
    setLoading(true); // Start spinner
  
    const formData = new FormData();
    formData.append('title', statsData.title);
    formData.append('subtitle', statsData.subtitle);
  
    // Add the existing background image path if no new file is uploaded
    if (statsData.background_image instanceof File) {
      formData.append('background_image', statsData.background_image);
    } else {
      formData.append('existing_background_image', statsData.background_image); // Add existing image path
    }
  
    statsData.cards.forEach((card, index) => {
      formData.append(`cards[${index}][title]`, card.title);
      formData.append(`cards[${index}][count]`, card.count);
    });
  
    axios.post(UPDATE_URL, formData)
      .then(() => {
        Swal.fire('Success!', 'Data updated successfully!', 'success'); // Show success message
      })
      .catch(error => {
        Swal.fire('Error!', 'Error updating data!', 'error'); // Show error message
      })
      .finally(() => {
        setLoading(false); // Stop spinner when done
      });
  };
  
  
  

  return (
    <Box
      sx={{
        position: 'relative',
        height: { xs: 'auto', md: '700px' },
        backgroundImage: `url(${previewImage})`,  // Use the previewImage URL for the background
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        textAlign: 'right',
        padding: '20px',
        marginTop: { xs: '100px', md: '0' },
      }}
    >
      <Button variant="contained" onClick={() => setIsEditing(!isEditing)}>
        {isEditing ? 'Cancel Edit' : 'Edit'}
      </Button>

      {isEditing ? (
        <>
          <TextField
            label="Title"
            name="title"
            value={statsData.title}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            sx={{ input: { color: 'white' }, label: { color: 'white' }, fieldset: { borderColor: 'white' } }}
          />
          <TextField
            label="Subtitle"
            name="subtitle"
            value={statsData.subtitle}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            sx={{ input: { color: 'white' }, label: { color: 'white' }, fieldset: { borderColor: 'white' } }}
          />

          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
            <Button
              variant="outlined"
              component="label"
              sx={{ display: 'flex', alignItems: 'center', borderColor: 'white', color: 'white' }}
            >
              <CloudUploadIcon sx={{ marginRight: '10px' }} />
              Upload Background Image
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
            {previewImage && (
              <img src={previewImage} alt="Preview" width="200" style={{ marginLeft: '20px' }} />
            )}
          </Box>
        </>
      ) : (
        <Typography variant="h3" sx={{ fontSize: { xs: '40px', md: '60px' }, fontWeight: '800' }}>
          {statsData.title}
          <br />
          {statsData.subtitle}
        </Typography>
      )}

      <Grid container spacing={2} justifyContent="flex-end" sx={{ marginTop: '30px' }}>
        {statsData.cards.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Box
              sx={{
                backgroundColor: '#242636',
                borderRadius: '15px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                minHeight: '200px',
                textAlign: 'center',
              }}
            >
              {isEditing ? (
                <>
                  <TextField
                    label="Card Title"
                    name="title"
                    value={card.title}
                    onChange={(e) => handleCardChange(e, index)}
                    sx={{ input: { color: 'white' }, label: { color: 'white' }, fieldset: { borderColor: 'white' } }}
                  />
                  <TextField
                    label="Card Count"
                    name="count"
                    value={card.count}
                    onChange={(e) => handleCardChange(e, index)}
                    sx={{ input: { color: 'white' }, label: { color: 'white' }, fieldset: { borderColor: 'white' } }}
                  />
                </>
              ) : (
                <>
                  <Typography variant="h2" sx={{ fontSize: { xs: '30px', md: '48px' }, fontWeight: 'bold' }}>
                    {card.count}+
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: { xs: '16px', md: '24px' }, marginTop: '10px' }}>
                    {card.title}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>

      {isEditing && (
        <Button variant="contained" onClick={handleSave} sx={{ marginTop: '20px' }}>
          {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Save Changes'}
        </Button>
      )}
    </Box>
  );
};

export default EditableStatsSection;
