import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, Box, Typography, Grid, useMediaQuery } from '@mui/material';
import swal from 'sweetalert';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const ContactForm = () => {
  const { handleSubmit, control } = useForm();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onSubmit = async (data) => {
    try {
      const response = await fetch('http://ertaqybackend.ertaqyagency-e.com/contact_form.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        swal("نجاح", "تم إرسال رسالتك بنجاح!", "success");
      } else {
        swal("خطأ", "حدث خطأ أثناء إرسال رسالتك. يرجى المحاولة مرة أخرى.", "error");
      }
    } catch (error) {
      swal("خطأ", "حدث خطأ أثناء الاتصال بالخادم.", "error");
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: '#2c3e50',
        borderRadius: 2,
        p: 4,
        color: 'white',
        textAlign: 'center',
        maxWidth: '100%',
        margin: '50px auto',
        direction: 'rtl',
        fontFamily: 'Cairo, sans-serif'
      }}
    >
      <Typography variant="h4" fontWeight="bold" sx={{ fontFamily: 'Cairo, sans-serif' }}>
        اتصل بنا
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 4, fontFamily: 'Cairo, sans-serif' }}>
        بعض المعلومات البسيطة وسنتواصل معك
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} style={{ padding: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="الاسم"
                  variant="filled"
                  sx={{
                    backgroundColor: '#34495e',
                    borderRadius: 1,
                    fontFamily: 'Cairo, sans-serif',
                    '& .MuiInputLabel-root': {
                      right: 20,
                      left: 'auto',
                      transformOrigin: 'right',
                    },
                    '& .MuiFilledInput-root': {
                      textAlign: 'right',
                    },
                  }}
                  InputLabelProps={{ style: { color: '#ecf0f1', fontFamily: 'Cairo, sans-serif', direction: 'rtl' } }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="البريد الإلكتروني"
                  variant="filled"
                  sx={{
                    backgroundColor: '#34495e',
                    borderRadius: 1,
                    fontFamily: 'Cairo, sans-serif',
                    '& .MuiInputLabel-root': {
                      right: 20,
                      left: 'auto',
                      transformOrigin: 'right',
                    },
                    '& .MuiFilledInput-root': {
                      textAlign: 'right',
                    },
                  }}
                  InputLabelProps={{ style: { color: '#ecf0f1', fontFamily: 'Cairo, sans-serif', direction: 'rtl' } }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="رقم الهاتف"
                  variant="filled"
                  sx={{
                    backgroundColor: '#34495e',
                    borderRadius: 1,
                    fontFamily: 'Cairo, sans-serif',
                    '& .MuiInputLabel-root': {
                      right: 20,
                      left: 'auto',
                      transformOrigin: 'right',
                    },
                    '& .MuiFilledInput-root': {
                      textAlign: 'right',
                    },
                  }}
                  InputLabelProps={{ style: { color: '#ecf0f1', fontFamily: 'Cairo, sans-serif', direction: 'rtl' } }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="budget"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="الميزانية"
                  variant="filled"
                  sx={{
                    backgroundColor: '#34495e',
                    borderRadius: 1,
                    fontFamily: 'Cairo, sans-serif',
                    '& .MuiInputLabel-root': {
                      right: 20,
                      left: 'auto',
                      transformOrigin: 'right',
                    },
                    '& .MuiFilledInput-root': {
                      textAlign: 'right',
                    },
                  }}
                  InputLabelProps={{ style: { color: '#ecf0f1', fontFamily: 'Cairo, sans-serif', direction: 'rtl' } }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="storeLink"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="رابط المتجر"
                  variant="filled"
                  sx={{
                    backgroundColor: '#34495e',
                    borderRadius: 1,
                    fontFamily: 'Cairo, sans-serif',
                    '& .MuiInputLabel-root': {
                      right: 20,
                      left: 'auto',
                      transformOrigin: 'right',
                    },
                    '& .MuiFilledInput-root': {
                      textAlign: 'right',
                    },
                  }}
                  InputLabelProps={{ style: { color: '#ecf0f1', fontFamily: 'Cairo, sans-serif', direction: 'rtl' } }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="source"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="من أين عرفت عن موقعنا؟"
                  variant="filled"
                  sx={{
                    backgroundColor: '#34495e',
                    borderRadius: 1,
                    fontFamily: 'Cairo, sans-serif',
                    '& .MuiInputLabel-root': {
                      right: 20,
                      left: 'auto',
                      transformOrigin: 'right',
                    },
                    '& .MuiFilledInput-root': {
                      textAlign: 'right',
                    },
                  }}
                  InputLabelProps={{ style: { color: '#ecf0f1', fontFamily: 'Cairo, sans-serif', direction: 'rtl' } }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="overview"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="نظرة عامة"
                  variant="filled"
                  multiline
                  rows={4}
                  sx={{
                    backgroundColor: '#34495e',
                    borderRadius: 1,
                    fontFamily: 'Cairo, sans-serif',
                    '& .MuiInputLabel-root': {
                      right: 20,
                      left: 'auto',
                      transformOrigin: 'right',
                    },
                    '& .MuiFilledInput-root': {
                      textAlign: 'right',
                    },
                  }}
                  InputLabelProps={{ style: { color: '#ecf0f1', fontFamily: 'Cairo, sans-serif', direction: 'rtl' } }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 4 }}>
          <Button type="submit" variant="contained" sx={{ backgroundColor: '#e74c3c', color: 'white', fontWeight: 'bold', borderRadius: 2, fontFamily: 'Cairo, sans-serif' }}>
            إرسال
          </Button>
        </Box>
      </form>

      {/* Contact Information Section */}
      <Box sx={{ 
        mt: 4, 
        fontFamily: 'Cairo, sans-serif', 
        textAlign: 'center', 
        display: 'flex', 
        flexDirection: { md: 'row', xs: 'column' }, 
        justifyContent: 'center', 
        alignItems: 'center',
        gap: 2 // Adds space between items
      }}>
        <Typography sx={{ fontFamily: 'Cairo, sans-serif', mx: 2 }}>
          📍( السعودية -مكة - الامام مسلم - حى الزاهر )
        </Typography>
        
        <Link style={{ color: "white", textDecoration: "none" }} to="/">
          <Typography sx={{ fontFamily: 'Cairo, sans-serif', mx: 2 }}>
            🌐 ertaqyagency-e.com
          </Typography>
        </Link>

        {/* Phone numbers with WhatsApp links */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <a href="https://wa.me/966553165725" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none', cursor: 'pointer' }}>
            <Typography sx={{ fontFamily: 'Cairo, sans-serif', mx: 2 }}>
              📞+966553165725
            </Typography>
          </a>
          <a href="https://wa.me/966553156779" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none', cursor: 'pointer' }}>
            <Typography sx={{ fontFamily: 'Cairo, sans-serif', mx: 2 }}>
              📞+966553156779
            </Typography>
          </a>
        </Box>

        <Typography sx={{ fontFamily: 'Cairo, sans-serif', mx: 2 }}>
          📧 info@ertaqyagency-e.com
        </Typography>
      </Box>

      {/* Social Media Icons */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, gap: 2 }}>
        <i className="fab fa-facebook-f icon" style={{ fontSize: '24px' }}></i>
        <i className="fab fa-twitter icon" style={{ fontSize: '24px' }}></i>
        <i className="fab fa-linkedin-in icon" style={{ fontSize: '24px' }}></i>
        <i className="fab fa-instagram icon" style={{ fontSize: '24px' }}></i>
      </Box>
      <Typography sx={{ mt: 4, fontSize: '0.75rem', color: '#bdc3c7', fontFamily: 'Cairo, sans-serif' }}>
        سنتواصل معك في أقرب وقت ممكن، عادة من يوم إلى ثلاثة أيام. السبب في ذلك هو أننا نتلقى مئات الطلبات شهريًا ونجلس لمراجعة كل مشروع وفقًا لما يناسب شروطنا. الأمر يستغرق وقتًا لأننا نختار عملاءنا بعناية.
      </Typography>
    </Box>
  );
};

export default ContactForm;
