import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardMedia, CardContent, CardActions, Button, CircularProgress, Dialog, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Swal from 'sweetalert2'; // Import SweetAlert2

// Base URL for images
const BASE_URL = "https://backendforerpsystem.zbooma.com/";

const PartnersChangeContent = () => {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editPartner, setEditPartner] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [newPartnerDialog, setNewPartnerDialog] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); // Track whether we are editing
  const [newPartner, setNewPartner] = useState({ name: '', image: null });

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await fetch('https://backendforerpsystem.zbooma.com/getPartners.php');
        const data = await response.json();

        if (data.success) {
          setPartners(data.data); // Set partners data
        } else {
          setError(data.message);
        }
      } catch (error) {
        setError('Failed to fetch data');
      } finally {
        setLoading(false); // Set loading to false once fetch is complete
      }
    };

    fetchPartners();
  }, []);

  const handleEdit = (partner) => {
    setIsEditMode(true); // Enable edit mode
    setEditPartner(partner); // Set the current partner to edit
    setPreview(`${BASE_URL}${partner.image_url}`); // Set the image preview
    setNewPartnerDialog(true); // Open the dialog
  };

  const handleDelete = async (id) => {
    // SweetAlert2 Confirmation before deleting
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await fetch(`https://backendforerpsystem.zbooma.com/EditOrUpdatedPartners.php`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id })
        });
        setPartners(partners.filter((partner) => partner.id !== id));

        // Show success message
        Swal.fire(
          'Deleted!',
          'The partner has been deleted.',
          'success'
        );
      }
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    // Preview the image
    const fileUrl = URL.createObjectURL(file);
    setPreview(fileUrl);
  };

  const handleSave = async () => {
    const formData = new FormData();
    if (isEditMode) {
      formData.append('id', editPartner.id);
      formData.append('name', editPartner.name);
      if (selectedFile) {
        formData.append('image', selectedFile);
      } else {
        formData.append('image_url', editPartner.image_url); // Send existing image URL if no new file
      }
    } else {
      formData.append('name', newPartner.name);
      if (newPartner.image) {
        formData.append('image', newPartner.image);
      }
    }

    try {
      const response = await fetch('https://backendforerpsystem.zbooma.com/EditOrUpdatedPartners.php', {
        method: 'POST',
        body: formData
      });
      const data = await response.json();
      if (data.success) {
        if (isEditMode) {
          setPartners(partners.map(p => p.id === editPartner.id ? { ...editPartner, image_url: data.image_url } : p));
        } else {
          setPartners([...partners, { id: data.id, name: newPartner.name, image_url: data.image_url }]);
        }
        setNewPartnerDialog(false);
        setEditPartner(null);
        setSelectedFile(null);
        setPreview(null);
        setNewPartner({ name: '', image: null });

        // SweetAlert2 Success on Save
        Swal.fire({
          icon: 'success',
          title: isEditMode ? 'Success!' : 'New Partner Added!',
          text: isEditMode ? 'Partner has been updated successfully.' : 'New partner has been added successfully.'
        });
      } else {
        // SweetAlert2 Error on failure
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong. Please try again.'
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong. Please try again.'
      });
    }
  };

  const handleNewFileChange = (e) => {
    const file = e.target.files[0];
    if (isEditMode) {
      setEditPartner((prev) => ({ ...prev, image: file }));
    } else {
      setNewPartner((prev) => ({ ...prev, image: file }));
    }

    // Preview the image
    const fileUrl = URL.createObjectURL(file);
    setPreview(fileUrl);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '40px 0', backgroundColor: '#f4f4f4', minHeight: '100vh' }}>
      <Typography variant="h4" sx={{ fontFamily: '"Cairo", sans-serif', textAlign: 'center', marginBottom: '40px', fontWeight: 'bold' }}>
        شركاؤنا
      </Typography>
      <div style={{ textAlign: "center", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Button variant="contained" startIcon={<AddIcon />} onClick={() => { setNewPartnerDialog(true); setIsEditMode(false); }} sx={{ mb: 4 }}>
          إضافة شريك جديد
        </Button>
      </div>

      <Grid container spacing={4} justifyContent="center">
        {partners.map((partner) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={partner.id}>
            <Card sx={{ maxWidth: 345, margin: 'auto', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', borderRadius: '10px', backgroundColor: "black" }}>
              <CardMedia component="img" alt={partner.name} image={`${BASE_URL}${partner.image_url}`} sx={{ objectFit: 'contain', padding: '10px', maxWidth: '200px', margin: '0 auto', height: '200px' }} />
              <CardContent sx={{ backgroundColor: '#005177', textAlign: 'center', padding: '20px', color: '#fff' }}>
                <Typography variant="h6" sx={{ fontFamily: '"Cairo", sans-serif', fontWeight: '600' }}>
                  {partner.name}
                </Typography>
              </CardContent>

              <CardActions sx={{ justifyContent: 'center', backgroundColor: '#f0f0f0' }}>
                <Button startIcon={<EditIcon />} sx={{ color: '#1976d2' }} onClick={() => handleEdit(partner)}>
                  تعديل
                </Button>
                <Button startIcon={<DeleteIcon />} sx={{ color: '#d32f2f' }} onClick={() => handleDelete(partner.id)}>
                  حذف
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Dialog for Adding/Editing Partner */}
      {newPartnerDialog && (
       <Dialog
       style={{ textAlign: "center" }}
       open={newPartnerDialog}
       onClose={() => setNewPartnerDialog(false)}
       aria-labelledby="form-dialog-title"
     >
       <Box sx={{ padding: '20px', minWidth: '300px', textAlign: "center" }}>
         <Typography variant="h6" mb={2} id="form-dialog-title">
           {isEditMode ? 'تعديل الشريك' : 'إضافة شريك جديد'}
         </Typography>
         <TextField
           label="Name"
           fullWidth
           autoFocus
           value={isEditMode ? editPartner.name : newPartner.name}
           onChange={(e) => {
             if (isEditMode) {
               setEditPartner({ ...editPartner, name: e.target.value });
             } else {
               setNewPartner({ ...newPartner, name: e.target.value });
             }
           }}
         />
         
         {/* Image Upload Button and Preview */}
         <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
           <Button variant="contained" component="label" startIcon={<CloudUploadIcon />}>
             Upload Image
             <input type="file" hidden onChange={handleNewFileChange} />
           </Button>
           {preview && (
             <img src={preview} alt="Preview" style={{ width: '80px', height: '80px', objectFit: 'cover', borderRadius: '8px' }} />
           )}
         </Box>
         
         <Button variant="contained" sx={{ marginTop: '20px' }} onClick={handleSave}>
           {isEditMode ? 'تحديث' : 'إضافة'}
         </Button>
       </Box>
     </Dialog>
     
      )}
    </Box>
  );
};

export default PartnersChangeContent;
