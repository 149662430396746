import React, { useContext } from "react";
import { ThemeContext } from "../Components/ThemeContext"; // Import the ThemeContext
import HeroSection from "../Components/FirstBarSection/FirstBarSection";
import ProfessionalSection from "../Components/SliderClickable/SliderClickable";
import PartnerSlider from "../Components/SlidersOurPartners/SlidersOurPartners";
import FourCardLayout from "../Components/TracksComponent/TracksComponent";
import InfiniteCarousel from "../Components/ToolsLogos/ToolsLogos";
import OurCustomers from "../Components/OurCustomers/OurCustomers";
import ContactForm from "../Components/ContactUs/ContactUs";
import CardSlider from "../Components/TracksSlidersAfterContactUs/TracksSlidersAfterContactUs";
import CharacterSlider from "../Components/Heros/Heros";
import CommonQuestions from "../Components/CommoneQuestions/CommoneQuestions";
import Footer from "../Components/Footer/Footer";
import NavBar from "../Components/NavBar/NavBar";
import OurServices from "../Components/OurServices/OurServices";
import Section2 from "../Components/Section2/Section2";
import ContactFormNew from "../Components/ContactFormNew/ContactFormNew";

const Home = () => {
  const { themeMode } = useContext(ThemeContext); // Get the current theme mode

  // Log the theme mode to the console
  console.log("Current Theme Mode:", themeMode);

  return (
    <div
      style={{
        backgroundColor: themeMode === 'dark' ? '#333' : '#fff', // Dark or light background based on theme
        color: themeMode === 'dark' ? '#fff' : '#000', // Adjust text color accordingly
        minHeight: '100vh', // Ensure the background covers the full viewport height
      }}
    >
      <NavBar />
      <HeroSection />
      <Section2/>
      {/* <ProfessionalSection /> */}
      {/* <PartnerSlider />
      <FourCardLayout />
      <InfiniteCarousel /> */}
      {/* <OurCustomers />}
      <ContactForm /> */}
      {/* <CardSlider />
      <OurServices /> */}
      {/* <OurCustomers/> */}
      <CommonQuestions />
      <ContactFormNew/>
      <Footer />
    </div>
  );
};

export default Home;
