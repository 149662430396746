import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TextField, Button, Box } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Swal from 'sweetalert2';

const API_URL = 'https://backendforerpsystem.zbooma.com/common_questions.php';
const UPDATE_URL = 'https://backendforerpsystem.zbooma.com/update_common_questions.php';

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "white",
        borderRadius: "50%",
        padding: "10px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
        zIndex: 2,
      }}
      onClick={onClick}
    >
      <ArrowBackIosIcon style={{ color: '#44AAB8', fontSize: '24px' }} />
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "white",
        borderRadius: "50%",
        padding: "10px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
        zIndex: 2,
      }}
      onClick={onClick}
    >
      <ArrowForwardIosIcon style={{ color: '#44AAB8', fontSize: '24px' }} />
    </div>
  );
};

const CommonQuestionsManager = () => {
  const [data, setData] = useState({ title: '', subtitle: '', questions: [] });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    axios.get(API_URL)
      .then(response => {
        if (response.data.success) {
          setData(response.data.data);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleQuestionChange = (e, index) => {
    const { name, value } = e.target;
    const updatedQuestions = data.questions.map((question, i) =>
      i === index ? { ...question, [name]: value } : question
    );
    setData(prevState => ({ ...prevState, questions: updatedQuestions }));
  };

  const handleSave = () => {
    axios.post(UPDATE_URL, data)
      .then(() => {
        Swal.fire('Success!', 'Data updated successfully!', 'success');
      })
      .catch(error => {
        Swal.fire('Error!', 'Error updating data!', 'error');
        console.error('Error updating data:', error);
      });
    setIsEditing(false);
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div style={styles.container}>
      {isEditing ? (
        <>
          <TextField
            label="Title"
            name="title"
            value={data.title}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            sx={{ input: { color: 'white' }, label: { color: 'white' }, fieldset: { borderColor: 'white' } }}
          />
          <TextField
            label="Subtitle"
            name="subtitle"
            value={data.subtitle}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            sx={{ input: { color: 'white' }, label: { color: 'white' }, fieldset: { borderColor: 'white' } }}
          />
          {data.questions.map((item, index) => (
            <Box key={index} sx={{ marginBottom: '20px' }}>
              <TextField
                label={`Question ${index + 1}`}
                name="question"
                value={item.question}
                onChange={(e) => handleQuestionChange(e, index)}
                fullWidth
                margin="normal"
                sx={{ input: { color: 'white' }, label: { color: 'white' }, fieldset: { borderColor: 'white' } }}
              />
              <TextField
                label={`Answer ${index + 1}`}
                name="answer"
                value={item.answer}
                onChange={(e) => handleQuestionChange(e, index)}
                fullWidth
                margin="normal"
                sx={{ input: { color: 'white' }, label: { color: 'white' }, fieldset: { borderColor: 'white' } }}
              />
            </Box>
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
            <Button variant="contained" onClick={handleSave} sx={{ backgroundColor: '#44AAB8', color: 'white' }}>
              Save
            </Button>
            <Button variant="outlined" onClick={() => setIsEditing(false)} sx={{ color: 'white', borderColor: 'white' }}>
              Cancel
            </Button>
          </Box>
        </>
      ) : (
        <>
          <h2 style={styles.title}>{data.title}</h2>
          <p style={styles.subtitle}>{data.subtitle}</p>
          <div style={styles.sliderWrapper}>
            <Slider style={{ paddingRight: "30px", paddingLeft: "30px" }} {...settings}>
              {data.questions.map((item, index) => (
                <div key={index} style={styles.questionSlide}>
                  <h3 style={styles.questionText}>{item.question}</h3>
                  <p style={styles.answerText}>{item.answer}</p>
                </div>
              ))}
            </Slider>
          </div>
        </>
      )}
      {!isEditing && (
        <Button variant="contained" onClick={() => setIsEditing(true)} sx={{ backgroundColor: '#44AAB8', color: 'white', marginTop: '20px' }}>
          Edit
        </Button>
      )}
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    padding: '30px 20px',
    backgroundColor: "#242636",
    color: 'white',
    fontFamily: 'Cairo, sans-serif',
  },
  title: {
    fontSize: '50px',
    fontWeight: '500',
    marginBottom: '10px',
    fontFamily: '"MO_Nawel", sans-serif',
  },
  subtitle: {
    fontSize: '16px',
    marginBottom: '20px',
    fontFamily: '"MO_Nawel", sans-serif',
    fontSize: '30px',
    fontWeight: '500',
  },
  sliderWrapper: {
    maxWidth: '700px',
    margin: '0 auto',
    backgroundColor: '#44AAB8',
    padding: '20px',
    borderRadius: '10px',
    position: 'relative',
    paddingTop: "100px",
    paddingBottom: "100px"
  },
  questionSlide: {
    padding: '20px',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  questionText: {
    fontSize: '22px',
    fontWeight: 'bold',
    marginBottom: '10px',
    fontFamily: '"MO_Nawel", sans-serif',
    fontSize: '30px',
    fontWeight: '400',
  },
  answerText: {
    fontSize: '16px',
    fontFamily: '"MO_Nawel", sans-serif',
    fontSize: '25px',
    fontWeight: '400',
  },
};

export default CommonQuestionsManager;
