import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Button, TextField, Typography, Paper, Box, InputLabel, Select, MenuItem, FormControl, IconButton, CardMedia, CircularProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload'; // Upload icon
import Swal from 'sweetalert2';

const MdowanaManagement = () => {
  const [articles, setArticles] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [newCategory, setNewCategory] = useState('');  // To handle new category input
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');  // To handle image preview
  const [editingId, setEditingId] = useState(null);  // To handle editing
  const [categories, setCategories] = useState(['إنشاء مواقع إلكترونية', 'تطوير الأعمال', 'تحليل', 'التجارة الإلكترونية', 'التعليم الإلكتروني']);
  const [loading, setLoading] = useState(false);  // Track if the request is in progress

  // Fetch all articles
  useEffect(() => {
    axios.get('https://backendforerpsystem.zbooma.com/articles.php')
      .then(response => {
        setArticles(response.data);
      })
      .catch(error => {
        Swal.fire({
          title: 'خطأ!',
          text: 'حدث خطأ أثناء تحميل المقالات!',
          icon: 'error',
        });
      });
  }, []);

  // Handle adding and editing articles
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);  // Set loading to true when the request starts
    
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('category', category ? category : newCategory);

    // Append the image if it's newly uploaded
    if (image) formData.append('image', image);
    
    // If updating, send the article ID as a hidden field
    if (editingId) formData.append('id', editingId);
  
    const url = 'https://backendforerpsystem.zbooma.com/articles.php'; // Always POST
  
    axios({
      method: 'POST',
      url: url,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    })
      .then(() => {
        Swal.fire({
          title: 'نجاح!',
          text: editingId ? 'تم تحديث المقال بنجاح!' : 'تم حفظ المقال بنجاح!',
          icon: 'success',
        }).then(() => {
          window.location.reload(); // Reload the page after save
        });
      })
      .catch(() => {
        Swal.fire({
          title: 'خطأ!',
          text: 'حدث خطأ أثناء حفظ المقال!',
          icon: 'error',
        });
      })
      .finally(() => {
        setLoading(false);  // Set loading to false once the request is finished
      });
  };
  
  // Handle deleting an article with confirmation
  const handleDelete = (id) => {
    Swal.fire({
      title: 'هل أنت متأكد؟',
      text: "لن تتمكن من التراجع عن هذا!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'نعم، احذفه!',
      cancelButtonText: 'إلغاء'
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true); // Set loading to true when deleting
        axios({
          method: 'DELETE',
          url: `https://backendforerpsystem.zbooma.com/articles.php`,
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify({ id }),
        }).then(() => {
          Swal.fire(
            'تم الحذف!',
            'تم حذف المقال بنجاح.',
            'success'
          );
          setArticles(articles.filter(article => article.id !== id));
        }).catch(() => {
          Swal.fire('خطأ!', 'حدث خطأ أثناء حذف المقال!', 'error');
        }).finally(() => {
          setLoading(false);  // Set loading to false after deletion
        });
      }
    });
  };

  // Handle image upload
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);

    // Show image preview
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle adding new category
  const handleAddCategory = () => {
    if (newCategory && !categories.includes(newCategory)) {
      setCategories([...categories, newCategory]);
      setCategory(newCategory);
      setNewCategory('');
      Swal.fire({
        title: 'تمت الإضافة!',
        text: 'تمت إضافة الفئة الجديدة بنجاح.',
        icon: 'success',
      });
    }
  };

  // Handle resetting form and cancel editing
  const handleCancelEdit = () => {
    setEditingId(null);
    setTitle('');
    setDescription('');
    setCategory('');
    setImage(null);
    setImagePreview('');
    Swal.fire({
      title: 'إلغاء التعديل',
      text: 'تم إلغاء التعديل بنجاح.',
      icon: 'info',
    });
  };

  return (
    <Box sx={{ padding: '20px', direction: 'rtl', fontFamily: 'Cairo, sans-serif' }}>
      <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Cairo, sans-serif', textAlign: 'center' }}>
        إدارة المدونة
      </Typography>

      <Paper elevation={3} sx={{ padding: '20px', marginBottom: '20px', fontFamily: 'Cairo, sans-serif' }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="عنوان المقال"
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                sx={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="الوصف"
                fullWidth
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                sx={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif' }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel sx={{ fontFamily: 'Cairo, sans-serif' }}>الفئة</InputLabel>
                <Select
                  fullWidth
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  sx={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif' }}
                >
                  {categories.map((cat, index) => (
                    <MenuItem key={index} value={cat} sx={{ fontFamily: 'Cairo, sans-serif' }}>
                      {cat}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Field to add new category */}
            <Grid item xs={12}>
              <TextField
                label="أضف فئة جديدة"
                fullWidth
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                sx={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif' }}
              />
              <Button variant="outlined" color="primary" onClick={handleAddCategory} sx={{ marginTop: '10px', fontFamily: 'Cairo, sans-serif' }}>
                إضافة فئة جديدة
              </Button>
            </Grid>

            {/* Image Upload */}
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <IconButton component="label" sx={{ marginBottom: '10px', borderRadius: '8px', backgroundColor: '#f5f5f5', padding: '10px' }}>
                <CloudUploadIcon fontSize="large" />
                <input type="file" accept="image/*" hidden onChange={handleImageChange} />
              </IconButton>
              {imagePreview && (
                <CardMedia
                  component="img"
                  image={imagePreview}
                  alt="Preview"
                  sx={{ width: '200px', height: '200px', borderRadius: '8px', border: '1px solid #ddd', objectFit: 'cover' }}
                />
              )}
              <Typography sx={{ fontFamily: 'Cairo, sans-serif', marginTop: '10px' }}>قم برفع صورة المقال</Typography>
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="contained" color="primary" type="submit" sx={{ fontFamily: 'Cairo, sans-serif' }} disabled={loading}>
                {loading ? <CircularProgress size={24} color="inherit" /> : editingId ? 'تحديث المقال' : 'حفظ المقال'}
              </Button>
              {editingId && (
                <Button variant="outlined" color="secondary" onClick={handleCancelEdit} sx={{ fontFamily: 'Cairo, sans-serif' }} disabled={loading}>
                  {loading ? <CircularProgress size={24} color="inherit" /> : 'إلغاء التعديل'}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Paper>

      <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Cairo, sans-serif', textAlign: 'center' }}>جميع المقالات</Typography>
      {articles.map((article) => (
        <Paper key={article.id} elevation={1} sx={{ padding: '10px', marginBottom: '10px', fontFamily: 'Cairo, sans-serif' }}>
          <Typography variant="h6" sx={{ fontFamily: 'Cairo, sans-serif' }}>{article.title}</Typography>
          <Typography variant="body2" sx={{ fontFamily: 'Cairo, sans-serif' }}>{article.description}</Typography>
          <Typography variant="caption" sx={{ fontFamily: 'Cairo, sans-serif' }}>{article.category}</Typography>

          {article.image && (
            <CardMedia
              component="img"
              image={article.image}
              alt={article.title}
              sx={{ width: '200px', height: '200px', borderRadius: '8px', border: '1px solid #ddd', objectFit: 'cover', marginTop: '10px' }}
            />
          )}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
            <Button variant="contained" color="secondary" onClick={() => handleDelete(article.id)} sx={{ fontFamily: 'Cairo, sans-serif' }} disabled={loading}>
              {loading ? <CircularProgress size={24} color="inherit" /> : 'حذف'}
            </Button>
            <Button variant="outlined" color="primary" onClick={() => {
              setEditingId(article.id);
              setTitle(article.title);
              setDescription(article.description);
              setCategory(article.category);
              setImagePreview(article.image);
            }} sx={{ fontFamily: 'Cairo, sans-serif' }}>
              تعديل
            </Button>
          </Box>
        </Paper>
      ))}
    </Box>
  );
};

export default MdowanaManagement;
