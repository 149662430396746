import React, { useState, useEffect, useContext } from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailIcon from '@mui/icons-material/Mail'; // Example of a second icon
import { ThemeContext } from '../ThemeContext';

const InfiniteCarousel = () => {
  const [sliderImages, setSliderImages] = useState([]);
  const { themeMode } = useContext(ThemeContext); // Access the theme mode
  const isDarkMode = themeMode === 'dark'; // Determine if dark mode is active

  // Fetch images from API
  useEffect(() => {
    const fetchSliderData = async () => {
      try {
        const response = await fetch('https://backendforerpsystem.zbooma.com/getToolsSlider.php');
        const data = await response.json();
        if (data.success) {
          // Process the fetched images
          const images = data.data.map(item => ({
            src: `https://backendforerpsystem.zbooma.com/${item.image_path}`,
            alt: item.title
          }));
          setSliderImages(images);
        }
      } catch (error) {
        console.error('Error fetching slider data:', error);
      }
    };

    fetchSliderData();
  }, []);

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/966553165725', '_blank');
  };

  const handleEmailClick = () => {
    window.open('mailto:support@example.com', '_blank');
  };

  const containerStyle = {
    backgroundColor: isDarkMode ? '#121212' : '#e0e0e0',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
  };

  const logosStyle = {
    overflow: 'hidden',
    padding: '20px 0',
    whiteSpace: 'nowrap',
    position: 'relative',
    backgroundColor: isDarkMode ? '#1e1e1e' : '#f0f0f0',
  };

  const contactButtonStyle = {
    fontFamily: 'Cairo, sans-serif',
    fontWeight: '600',
    backgroundColor: isDarkMode ? 'transparent' : '#25d366',
    color: isDarkMode ? '#fff' : '#fff',
    padding: '10px 20px',
    border: `2px solid ${isDarkMode ? '#fff' : '#25d366'}`,
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  };

  const emailButtonStyle = {
    fontFamily: 'Cairo, sans-serif',
    fontWeight: '600',
    backgroundColor: isDarkMode ? 'transparent' : '#007bff',
    color: isDarkMode ? '#fff' : '#fff',
    padding: '10px 20px',
    border: `2px solid ${isDarkMode ? '#fff' : '#007bff'}`,
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
    marginLeft: '10px', // Add spacing between buttons
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    minWidth: '130px',
  };

  return (
    <div style={containerStyle}>
      <h1 style={{ fontFamily: '"MO_Nawel", sans-serif', fontWeight: '800', fontSize: '50px' }}>أدواتــنــا</h1>

      {/* Slider 1 */}
      <div style={logosStyle}>
        <style>
          {`
            @keyframes slides {
              from {
                transform: translateX(0);
              }
              to {
                transform: translateX(-100%);
              }
            }

            @keyframes reverse-slides {
              from {
                transform: translateX(-100%);
              }
              to {
                transform: translateX(0);
              }
            }

            .logos {
              overflow: hidden;
              white-space: nowrap;
              position: relative;
              padding: 20px;
              border-radius: 10px;
              margin-bottom: 10px; /* Reduced space between sliders */
            }

            .logos:before, .logos:after {
              position: absolute;
              top: 0;
              content: '';
              width: 250px;
              height: 100%;
              z-index: 2;
            }

            .logos:before {
              left: 0;
              background: linear-gradient(to left, rgba(255,255,255,0), rgb(255, 255, 255));
            }

            .logos:after {
              right: 0;
              background: linear-gradient(to right, rgba(255,255,255,0), rgb(255, 255, 255));
            }

            .logo_items {
              display: inline-block;
              animation: 35s slides infinite linear;
            }

            .reverse .logo_items {
              animation: 35s reverse-slides infinite linear;
            }

            .logos:hover .logo_items {
              animation-play-state: paused;
            }

            .logo_items div {
              display: inline-block;
              background: #fff;
              border-radius: 10px;
              padding: 10px;
              margin: 0 10px;
            }

            .logo_items img {
              height: 80px;
            }
          `}
        </style>
        <div className="logo_items">
          {sliderImages.map((img, index) => (
            <div key={index}>
              <img src={img.src} alt={img.alt} />
            </div>
          ))}
        </div>
        <div className="logo_items">
          {sliderImages.map((img, index) => (
            <div key={index}>
              <img src={img.src} alt={img.alt} />
            </div>
          ))}
        </div>
      </div>

      {/* Slider 2 */}
      <div style={logosStyle} className="reverse">
        <div className="logo_items">
          {sliderImages.map((img, index) => (
            <div key={index}>
              <img src={img.src} alt={img.alt} />
            </div>
          ))}
        </div>
        <div className="logo_items">
          {sliderImages.map((img, index) => (
            <div key={index}>
              <img src={img.src} alt={img.alt} />
            </div>
          ))}
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button style={contactButtonStyle} className="contact-button" onClick={handleWhatsAppClick}>
          <WhatsAppIcon size={25} /> تواصل معنا
        </button>
        <button style={emailButtonStyle} className="contact-button" onClick={handleEmailClick}>
          عــنا
        </button>
      </div>
    </div>
  );
};

export default InfiniteCarousel;
