import React, { useState, useEffect } from 'react';
import './ProfessionalSectionThird.css';
import smallItem1 from '../../../images/Ellipse 9.svg';
import smallItem2 from '../../../images/Ellipse 10.svg';

const StatsSection = () => {
  const [statsData, setStatsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data from the API
  useEffect(() => {
    fetch('https://backendforerpsystem.zbooma.com/stats_section_cards.php')
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setStatsData(data.data); // Store the API data in state
        } else {
          setError('Failed to fetch stats data');
        }
        setLoading(false);
      })
      .catch((error) => {
        setError('An error occurred while fetching data');
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!statsData.length) {
    return <div>No data available</div>;
  }

  return (
    <div className="stats-section">
      <div className="stats-cards-container">
        {statsData.map((stat, index) => (
          <div className="stats-card" key={stat.id}>
            {/* Add decorative images only for the first and last cards */}
            {index === 0 && <img src={smallItem1} alt="Decoration" className="stats-small-item top-left" />}
            {index === statsData.length - 1 && <img src={smallItem2} alt="Decoration" className="stats-small-item top-right" />}
            
            <h1>{stat.stat_value}</h1>
            <p>{stat.stat_description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatsSection;
