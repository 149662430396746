import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Card, CardContent, Grid } from '@mui/material';

const ContactUsSectionProtofiloManagement = () => {
  const [contactData, setContactData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [updatedData, setUpdatedData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(''); // Image preview for new uploads

  // Fetch data from API
  useEffect(() => {
    fetch('https://backendforerpsystem.zbooma.com/contact_us_section_protofilo.php')
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setContactData(data.data);
          setUpdatedData(data.data); // Set initial data for editing
        } else {
          setError('Failed to fetch contact data');
        }
        setLoading(false);
      })
      .catch((error) => {
        setError('An error occurred while fetching data');
        setLoading(false);
      });
  }, []);

  // Handle form field change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData({ ...updatedData, [name]: value });
  };

  // Handle file change for image
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    // Generate a preview of the selected image
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result); // Set the image preview URL
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // Handle form submission using onClick
  const handleSaveClick = async () => {
    console.log("Save button clicked");

    // Create a FormData object to hold the form data
    const formData = new FormData();
    formData.append('title', updatedData.title);
    formData.append('description', updatedData.description);
    formData.append('phone', updatedData.phone);
    formData.append('web_address', updatedData.web_address);
    formData.append('location', updatedData.location);

    // If a new image is selected, append it to the form data
    if (selectedFile) {
      console.log("A file is selected:", selectedFile.name);
      formData.append('image', selectedFile);
    }

    try {
      // Send the form data to the PHP backend
      const response = await fetch('https://backendforerpsystem.zbooma.com/update_contact_us_section_protofilo.php', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      console.log("Response data:", data); // Log response for debugging

      if (data.success) {
        Swal.fire({
          title: 'Success',
          text: 'Contact details updated successfully!',
          icon: 'success',
        });

        // Update the displayed content with the new data
        setContactData({
          ...updatedData,
          image_path: selectedFile ? data.image_path : contactData.image_path, // Use new image path if uploaded, else keep the old one
        });

        setEditMode(false); // Exit edit mode
        setImagePreview(''); // Clear the image preview after successful save
      } else {
        Swal.fire({
          title: 'Error',
          text: data.message || 'Failed to update contact details',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('Error during submission:', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while updating contact details',
        icon: 'error',
      });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!contactData) {
    return <div>No contact data available</div>;
  }

  return (
    <div className="contact-section-protofilo">
      <Card sx={{ maxWidth: 600, margin: 'auto', padding: '20px' }}>
        <CardContent>
          {editMode ? (
            <Dialog open={editMode} onClose={() => setEditMode(false)} fullWidth maxWidth="sm">
              <DialogTitle>Edit Contact Information</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Title"
                      name="title"
                      value={updatedData.title}
                      onChange={handleInputChange}
                      fullWidth
                      variant="outlined"
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Description"
                      name="description"
                      value={updatedData.description}
                      onChange={handleInputChange}
                      fullWidth
                      multiline
                      rows={4}
                      variant="outlined"
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Phone"
                      name="phone"
                      value={updatedData.phone}
                      onChange={handleInputChange}
                      fullWidth
                      variant="outlined"
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Website"
                      name="web_address"
                      value={updatedData.web_address}
                      onChange={handleInputChange}
                      fullWidth
                      variant="outlined"
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Location"
                      name="location"
                      value={updatedData.location}
                      onChange={handleInputChange}
                      fullWidth
                      variant="outlined"
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div>
                      <label htmlFor="upload-image">
                        Upload New Image:
                        <input type="file" onChange={handleFileChange} id="upload-image" />
                      </label>
                    </div>

                    {/* Show image preview if a new image is uploaded, else show current image */}
                    {imagePreview ? (
                      <img
                        src={imagePreview}
                        alt="New Upload"
                        style={{ width: '100%', maxWidth: '300px', height: 'auto', marginTop: '10px' }}
                      />
                    ) : (
                      <img
                        src={`https://backendforerpsystem.zbooma.com/${contactData.image_path}`}
                        alt="Contact Us"
                        style={{ width: '100%', maxWidth: '300px', height: 'auto', marginTop: '10px' }}
                      />
                    )}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setEditMode(false)} color="secondary">Cancel</Button>
                <Button onClick={handleSaveClick} color="primary">Save Changes</Button>
              </DialogActions>
            </Dialog>
          ) : (
            <>
              <div className="contact-image" style={{ textAlign: 'center' }}>
                <img
                  src={`https://backendforerpsystem.zbooma.com/${contactData.image_path}`}
                  alt="Contact Us"
                  style={{ width: '100%', maxWidth: '300px', height: 'auto', borderRadius: '8px' }}
                />
              </div>
              <div className="contact-info">
                <h2>{contactData.title}</h2>
                <p>{contactData.description}</p>
                <div className="contact-details">
                  <p><strong>Phone:</strong> {contactData.phone}</p>
                  <p><strong>Website:</strong> {contactData.web_address}</p>
                  <p><strong>Location:</strong> {contactData.location}</p>
                </div>
              </div>
              <Button variant="contained" color="primary" onClick={() => setEditMode(true)} sx={{ mt: 3 }}>
                Edit
              </Button>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default ContactUsSectionProtofiloManagement;
