import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Swal from 'sweetalert2';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const API_URL = 'https://backendforerpsystem.zbooma.com/customers.php';

const CustomersManagement = () => {
  const [customers, setCustomers] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState({ id: null, name: '', description: '', image: null });
  const [isEditing, setIsEditing] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    fetchCustomers();
  }, []);

  // Fetch all customers
  const fetchCustomers = async () => {
    try {
      const response = await fetch(API_URL);
      const data = await response.json();
      setCustomers(data);
    } catch (error) {
      Swal.fire('Error', 'Failed to fetch customers', 'error');
    }
  };

  // Open dialog to add or edit a customer
  const handleOpenDialog = (customer = null) => {
    if (customer) {
      setCurrentCustomer(customer);
      setPreviewImage(`https://backendforerpsystem.zbooma.com/${customer.image_url}`);
      setIsEditing(true);
    } else {
      setCurrentCustomer({ id: null, name: '', description: '', image: null });
      setPreviewImage(null); // Reset preview image
      setIsEditing(false);
    }
    setOpen(true);
  };

  // Close dialog
  const handleCloseDialog = () => {
    setOpen(false);
    setCurrentCustomer({ id: null, name: '', description: '', image: null });
    setPreviewImage(null);
  };

  // Handle save (create or update customer)
  const handleSave = async () => {
    const formData = new FormData();

    if (currentCustomer.id) {
      formData.append('id', currentCustomer.id);
    }

    formData.append('name', currentCustomer.name); // Allow empty name
    formData.append('description', currentCustomer.description); // Allow empty description

    if (currentCustomer.image) {
      formData.append('image', currentCustomer.image); // Append image if available
    }

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      Swal.fire({
        icon: result.success ? 'success' : 'error',
        title: result.success ? 'Success' : 'Error',
        text: result.message,
      });
      fetchCustomers(); // Refresh the customer list
      handleCloseDialog(); // Close the dialog
    } catch (error) {
      Swal.fire('Error', 'Failed to save customer', 'error');
    }
  };

  // Handle delete customer
  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this customer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(API_URL, {
            method: 'DELETE',
            body: JSON.stringify({ id }),
          });
          const result = await response.json();
          Swal.fire(result.message);
          fetchCustomers();
        } catch (error) {
          Swal.fire('Error', 'Failed to delete customer', 'error');
        }
      }
    });
  };

  // Handle image upload and preview
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCurrentCustomer({ ...currentCustomer, image: file });
      setPreviewImage(URL.createObjectURL(file)); // Preview the image
    }
  };

  return (
    <Box>
      <Typography variant="h4" sx={{ marginBottom: '20px' }}>
        Manage Customers
      </Typography>

      <Button variant="contained" onClick={() => handleOpenDialog()}>Add Customer</Button>

      <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer) => (
              <TableRow key={customer.id}>
                <TableCell>{customer.name || 'N/A'}</TableCell> {/* Display N/A if name is empty */}
                <TableCell>{customer.description || 'N/A'}</TableCell> {/* Display N/A if description is empty */}
                <TableCell>
                  <img 
                    src={`https://backendforerpsystem.zbooma.com/${customer.image_url}`} 
                    alt={customer.name} 
                    style={{ 
                      width: '100px', 
                      height: '100px', 
                      objectFit: 'cover', 
                      borderRadius: '8px', 
                      border: '2px solid #ddd',
                      filter: 'invert(1)' // Invert the image colors to make white areas black
                    }} 
                  />
                </TableCell>
                <TableCell>
                  <Button variant="outlined" onClick={() => handleOpenDialog(customer)}>Edit</Button>
                  <Button variant="outlined" color="error" onClick={() => handleDelete(customer.id)} sx={{ marginLeft: '10px' }}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{isEditing ? 'Edit Customer' : 'Add Customer'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            fullWidth
            value={currentCustomer.name}
            onChange={(e) => setCurrentCustomer({ ...currentCustomer, name: e.target.value })}
            sx={{ marginBottom: '20px' }}
          />
          <TextField
            label="Description"
            fullWidth
            value={currentCustomer.description}
            onChange={(e) => setCurrentCustomer({ ...currentCustomer, description: e.target.value })}
            sx={{ marginBottom: '20px' }}
          />

          {/* Image Upload Button */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Button variant="contained" component="label" startIcon={<CloudUploadIcon />}>
              Upload Image
              <input type="file" hidden onChange={handleImageChange} />
            </Button>
            {previewImage && (
              <img 
                src={previewImage} 
                alt="Preview" 
                style={{ 
                  width: '80px', 
                  height: '80px', 
                  objectFit: 'cover', 
                  borderRadius: '8px',
                  filter: 'invert(1)' // Invert the preview image color to black
                }} 
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSave} variant="contained">Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CustomersManagement;
