import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Box, Card, CardContent, Typography, Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField, CircularProgress } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailIcon from '@mui/icons-material/Mail';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Swal from 'sweetalert2'; // Import SweetAlert2 correctly
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';

// Slider settings for Slick carousel
const sliderSettings = {
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 1 }},
    { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 1 }},
    { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 }},
  ],
};

const ToolsSlider = () => {
  const [sliderData, setSliderData] = useState([]);
  const [currentGroup, setCurrentGroup] = useState(null); // Current slider group being edited
  const [openDialog, setOpenDialog] = useState(false); // Dialog state for adding images
  const [newImage, setNewImage] = useState(null); // State for a new image
  const [loading, setLoading] = useState(false); // Loading state for fetch

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://backendforerpsystem.zbooma.com/getToolsSlider.php');
      if (response.data.success) {
        setSliderData(response.data.data);
      } else {
        Swal.fire('Error', 'No data found', 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'Failed to fetch data', 'error');
    } finally {
      setLoading(false);
    }
  };

  // Group images by slider_group (e.g., slider1, slider2)
  const groupedData = sliderData.reduce((groups, item) => {
    const group = item.slider_group;
    if (!groups[group]) {
      groups[group] = [];
    }
    groups[group].push(item);
    return groups;
  }, {});

  const handleWhatsAppClick = (url) => {
    window.open(url, '_blank');
  };

  const handleEmailClick = (email) => {
    window.open(`mailto:${email}`, '_blank');
  };

  // Open dialog to add new image
  const handleAddImageClick = (group) => {
    setCurrentGroup(group);
    setOpenDialog(true);
  };

  // Handle new image upload
  const handleNewImageChange = (event) => {
    setNewImage(event.target.files[0]);
  };

  // Submit new image to server
  const handleSubmitNewImage = async () => {
    if (newImage && currentGroup) {
      const formData = new FormData();
      formData.append('slider_group', currentGroup);
      formData.append('image', newImage);

      try {
        Swal.fire({
          title: 'Uploading...',
          text: 'Please wait',
          didOpen: () => {
            Swal.showLoading();
          }
        });
        await axios.post('https://backendforerpsystem.zbooma.com/uploadSliderImage.php', formData);
        await fetchData();
        Swal.fire('Success', 'Image uploaded successfully!', 'success');
      } catch (error) {
        Swal.fire('Error', 'Error uploading image', 'error');
      }
      setOpenDialog(false);
      setNewImage(null);
    }
  };

  // Delete an image
  const handleDeleteImage = async (imageId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This action cannot be undone',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.post('https://backendforerpsystem.zbooma.com/deleteSliderImage.php', { id: imageId });
          await fetchData();
          Swal.fire('Deleted!', 'Image has been deleted.', 'success');
        } catch (error) {
          Swal.fire('Error', 'Error deleting image', 'error');
        }
      }
    });
  };

  return (
    <Box sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#f5f5f5', borderRadius: '10px', width: "95%", margin: "auto" }}>
      <Typography variant="h4" sx={{ fontFamily: 'Cairo, sans-serif', fontWeight: '800', marginBottom: '30px', color: '#333' }}>
        أدواتــنــا
      </Typography>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Render each slider group */}
          {Object.keys(groupedData).map((group, index) => (
            <Box key={index} sx={{ marginBottom: '50px' }}>
              <Slider {...sliderSettings}>
                {groupedData[group].map((item) => (
                  <Card key={item.id} sx={{ padding: '10px', margin: '10px', backgroundColor:'#ffffff', borderRadius: '12px', boxShadow:'0 8px 16px rgba(0, 0, 0, 0.1)', position: 'relative' }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px', marginBottom: '15px' }}>
                        <img src={`https://backendforerpsystem.zbooma.com/${item.image_path}`} alt={item.title} style={{ maxHeight: '100%', maxWidth: '100%' }} />
                      </Box>
                      <Typography variant="h6" sx={{ color: '#333', fontSize: '16px', fontWeight: '600' }}>
                        {item.title}
                      </Typography>
                      {/* Delete Button */}
                      <IconButton
                        sx={{ position: 'absolute', top: 10, right: 10 }}
                        color="error"
                        onClick={() => handleDeleteImage(item.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CardContent>
                  </Card>
                ))}
              </Slider>
              {/* Add new image button */}
              <Button
                variant="outlined"
                startIcon={<AddPhotoAlternateIcon />}
                sx={{ marginTop: '20px', fontWeight: '600', color: '#007bff', borderColor: '#007bff' }}
                onClick={() => handleAddImageClick(group)}
              >
                Add New Image
              </Button>
            </Box>
          ))}

          {/* WhatsApp and Email Buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '40px', gap: '20px' }}>
            <Button
              variant="contained"
              startIcon={<WhatsAppIcon />}
              sx={{ backgroundColor: '#25D366', color: '#fff', fontWeight: '600', fontFamily: 'Cairo, sans-serif' }}
              onClick={() => handleWhatsAppClick(sliderData[0]?.whatsapp_content)}
            >
              تواصل معنا
            </Button>
            <Button
              variant="contained"
              startIcon={<MailIcon />}
              sx={{ backgroundColor: '#007bff', color: '#fff', fontWeight: '600', fontFamily: 'Cairo, sans-serif' }}
              onClick={() => handleEmailClick(sliderData[0]?.email_content)}
            >
              عــنا
            </Button>
          </Box>
        </>
      )}

      {/* Dialog for Adding New Image */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add New Image</DialogTitle>
        <DialogContent>
          <TextField
            type="file"
            fullWidth
            onChange={handleNewImageChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmitNewImage} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ToolsSlider;
