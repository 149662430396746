import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CountUp from "react-countup";
import Footer from "../Footer/Footer";
import NavBar from "../NavBar/NavBar";
import CommonQuestions from "../CommoneQuestions/CommoneQuestions";
import image from "../../images/MaskGroup24.png"; // Ensure this path is correct

const ServiceDetail = () => {
  const { serviceName } = useParams();
  const [serviceDetails, setServiceDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const decodedServiceName = decodeURIComponent(serviceName).trim();

    axios
      .get("https://backendforerpsystem.zbooma.com/handleservices.php")
      .then((response) => {
        const services = response.data;

        const service = services.find(
          (s) => s.service_name.trim() === decodedServiceName
        );

        if (service) {
          // Parse steps if it's a JSON string
          if (typeof service.steps === "string") {
            service.steps = JSON.parse(service.steps);
          }

          // Prepend the base URL to the image URL
          service.image_url = `https://backendforerpsystem.zbooma.com/${service.image_url}`;

          setServiceDetails(service);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching service details:", error);
        setLoading(false);
      });
  }, [serviceName]);

  if (loading) {
    return <Typography variant="h4">Loading...</Typography>;
  }

  if (!serviceDetails) {
    return <Typography variant="h4">Service not found</Typography>;
  }

  return (
    <>
      <NavBar />
      <Box
        sx={{
          height: { xs: "65vh", md: "80vh" },
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          boxShadow: "inset 0 0 0 1000px rgba(0, 0, 0, 0.1)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#30355b",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: "white",
            fontSize: { xs: "50px", md: "150px" },
            fontWeight: "500",
            textAlign: "center",
            fontFamily: '"MO_Nawel", sans-serif',
          }}
        >
          {serviceDetails.service_name}
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: "#30355b",
          padding: { xs: "30px 10px", md: "50px 0" },
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            direction: "rtl",
            fontFamily: '"MO_Nawel", sans-serif',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "1200px",
            margin: "0 auto",
            textAlign: "center",
            marginTop: "50px",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#42aaa6",
              borderRadius: "20px",
              width: { xs: "100%", md: "80%" },
              height: { xs: "auto", md: "250px" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "30px",
              marginTop: "80px",
            }}
          >
            <img
              src={serviceDetails.image_url}
              alt={serviceDetails.service_name}
              style={{ width: "100%", maxWidth: "400px", height: "auto" }}
            />
          </Box>
          <Box
            sx={{
              border: "2px solid #42aaa6",
              borderRadius: "5px",
              marginTop: "70px",
              padding: "20px",
              backgroundColor: "#424874",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: "white",
                marginBottom: "20px",
                fontFamily: '"MO_Nawel", sans-serif',
                fontSize: { xs: "24px", md: "40px" },
              }}
            >
              {serviceDetails.description || "لا توجد تفاصيل لهذه الخدمة"}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              marginTop: "20px",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#00bfa5",
                color: "white",
                padding: { xs: "10px 20px", md: "10px 30px" },
                fontSize: { xs: "16px", md: "20px" },
                borderRadius: "5px",
                fontFamily: '"MO_Nawel", sans-serif',
                width: { xs: "100%", md: "auto" },
              }}
              href={`https://wa.me/966553165725?text=${encodeURIComponent(
                `العميل يستفسر عن الخدمة: ${serviceDetails.service_name}`
              )}`}
              target="_blank"
            >
              تواصل معنا
            </Button>

            <Button
              variant="outlined"
              sx={{
                color: "white",
                border: "2px solid white",
                padding: { xs: "10px 20px", md: "10px 30px" },
                fontSize: { xs: "16px", md: "20px" },
                borderRadius: "5px",
                fontFamily: '"MO_Nawel", sans-serif',
                width: { xs: "100%", md: "auto" },
              }}
            >
              من نحن
            </Button>
          </Box>
        </Box>
      </Box>

      <Box sx={{ margin: "0 auto", textAlign: "center", direction: "rtl" }}>
        <Typography
          sx={{
            fontFamily: '"MO_Nawel", sans-serif',
            fontSize: { xs: "30px", md: "70px" },
            textAlign: "center",
          }}
        >
          تفاصيل الخدمة
        </Typography>

        {Array.isArray(serviceDetails.steps) && serviceDetails.steps.length > 0 ? (
          <Box
            sx={{
              marginTop: "30px",
              width: { xs: "100%", md: "80%" },
              margin: "0 auto",
            }}
          >
            {serviceDetails.steps.map((step, index) => (
              <Accordion key={index} style={{ padding: "20px", direction: "rtl" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "white", fontSize: "30px" }} />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  sx={{
                    backgroundColor: "#424874",
                    color: "white",
                    borderRadius: "5px",
                    marginBottom: "10px",
                    textAlign: "right",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: '"MO_Nawel", sans-serif',
                      fontSize: { xs: "20px", md: "30px" },
                      textAlign: "right",
                    }}
                  >
                    {step.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: "#30355b",
                    color: "white",
                    borderRadius: "5px",
                    fontFamily: '"MO_Nawel", sans-serif',
                    fontSize: { xs: "18px", md: "25px" },
                    textAlign: "right",
                    whiteSpace: "pre-line", // This will handle the new lines
                  }}
                >
                  {step.content}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        ) : (
          <Typography
            sx={{ color: "white", marginTop: "20px", fontSize: { xs: "18px", md: "24px" } }}
          >
            لا توجد خطوات متاحة لهذه الخدمة.
          </Typography>
        )}
      </Box>
      <Box style={{ backgroundColor: "#30355b", marginTop: "50px" }}>
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          sx={{
            backgroundColor: "#35425e",
            padding: { xs: "30px 10px", md: "50px" },
          }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                backgroundColor: "#242636",
                borderRadius: "15px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                minHeight: "250px",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontFamily: '"MO_Nawel", sans-serif',
                  fontWeight: "bold",
                  fontSize: { xs: "40px", md: "60px" },
                }}
              >
                <CountUp end={100} duration={3} prefix="+" />
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: '"MO_Nawel", sans-serif',
                  marginTop: "10px",
                  fontSize: { xs: "18px", md: "24px" },
                }}
              >
                عميل حالي
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                backgroundColor: "#242636",
                borderRadius: "15px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                minHeight: "250px",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontFamily: '"MO_Nawel", sans-serif',
                  fontWeight: "bold",
                  fontSize: { xs: "40px", md: "60px" },
                }}
              >
                <CountUp end={200} duration={3} prefix="+" />
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: '"MO_Nawel", sans-serif',
                  marginTop: "10px",
                  fontSize: { xs: "18px", md: "24px" },
                }}
              >
                مشروع مكتمل
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                backgroundColor: "#242636",
                borderRadius: "15px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                minHeight: "250px",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontFamily: '"MO_Nawel", sans-serif',
                  fontWeight: "bold",
                  fontSize: { xs: "40px", md: "60px" },
                }}
              >
                <CountUp end={50} duration={3} prefix="+" />
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: '"MO_Nawel", sans-serif',
                  marginTop: "10px",
                  fontSize: { xs: "18px", md: "24px" },
                }}
              >
                عضو في الفريق
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                backgroundColor: "#242636",
                borderRadius: "15px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                minHeight: "250px",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontFamily: '"MO_Nawel", sans-serif',
                  fontWeight: "bold",
                  fontSize: { xs: "40px", md: "60px" },
                }}
              >
                <CountUp end={10} duration={3} prefix="+" />
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: '"MO_Nawel", sans-serif',
                  marginTop: "10px",
                  fontSize: { xs: "18px", md: "24px" },
                }}
              >
                سنوات خبرة
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <CommonQuestions />
      <Footer />
    </>
  );
};

export default ServiceDetail;
