import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const VideoSlider = () => {
  const [videoSlides, setVideoSlides] = useState([]);

  // Fetch videos from the API
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch('https://backendforerpsystem.zbooma.com/getvideosSlider.php');
        const data = await response.json();
        
        if (data.success) {
          setVideoSlides(data.data);  // Set the fetched videos into state
        } else {
          console.error('Failed to fetch videos:', data.message);
        }
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos();
  }, []);

  return (
    <>
      <div style={{ width: "90%", margin: "auto" }}>
        <hr />
        <h3 style={{ color: "white", fontFamily: '"MO_Nawel", sans-serif', textAlign: "right", fontSize: "30px" }}>
          مــن أعمــالنا
        </h3>
      </div>
      <div className="container" style={{ maxWidth: '80%', margin: '0 auto', padding: '40px 0', position: 'relative' }}>
        {videoSlides.length > 0 ? (
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={1}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
                autoplay: {
                  delay: 3000,
                  disableOnInteraction: false,
                },
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
            }}
            spaceBetween={30}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false,
            }}
            pagination={{
              el: '.swiper-pagination',
              clickable: true,
              bulletClass: 'swiper-pagination-bullet',
              bulletActiveClass: 'swiper-pagination-bullet-active',
              renderBullet: (index, className) => {
                return `<span class="${className}" style="background-color: white;"></span>`;
              },
            }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
            className="swiper_container"
            style={{ height: '600px', width: '100%' }}
          >
            {videoSlides.map((slide) => (
              <SwiperSlide key={slide.id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                  <iframe
                    src={slide.video_src}
                    title={slide.title}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      borderRadius: '20px',
                    }}
                  ></iframe>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <p>Loading videos...</p>
        )}

        {/* Custom Navigation Arrows */}
        <div
          className="swiper-button-prev"
          style={{
            position: 'absolute',
            top: '50%',
            left: '-30px',  // Move arrow outside the slider
            transform: 'translateY(-50%)',
            backgroundColor: '#42aaa6',
            color: 'white',
            fontSize: '24px',
            zIndex: 10,
            cursor: 'pointer',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ion-icon name="chevron-back-outline"></ion-icon>
        </div>
        <div
          className="swiper-button-next"
          style={{
            position: 'absolute',
            top: '50%',
            right: '-30px',  // Move arrow outside the slider
            transform: 'translateY(-50%)',
            backgroundColor: '#42aaa6',
            color: 'white',
            fontSize: '24px',
            zIndex: 10,
            cursor: 'pointer',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
        <div className="swiper-pagination" style={{ bottom: '-30px' }}></div> {/* Move dots below the slider */}
      </div>
    </>
  );
}

export default VideoSlider;
