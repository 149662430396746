import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Slider from 'react-slick';
import { ThemeContext } from '../ThemeContext';
import axios from 'axios';

const API_URL = 'https://backendforerpsystem.zbooma.com/OurServices.php';

const OurServices = () => {
  const { themeMode } = useContext(ThemeContext); // Access the theme mode
  const isDarkMode = themeMode === 'dark'; // Determine if dark mode is active
  const [mainTitle, setMainTitle] = useState('');
  const [sliderOne, setSliderOne] = useState([]);
  const [sliderTwo, setSliderTwo] = useState([]);

  useEffect(() => {
    axios.get(API_URL)
      .then(response => {
        const data = response.data;
        setMainTitle(data.main_title);
        setSliderOne(JSON.parse(data.slider_one));
        setSliderTwo(JSON.parse(data.slider_two));
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    dots: false,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 1 }},
      { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 1 }},
      { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 }},
    ],
  };

  const inverseSettings = {
    ...settings,
    rtl: true, // Enable right-to-left sliding for inverse direction
  };

  return (
    <Box 
      sx={{ 
        padding: '40px', 
        textAlign: 'center', 
        direction: 'rtl', 
        backgroundColor: isDarkMode ? '#121212' : '#30355b', 
        overflow: 'hidden',
      }}
    >
      {/* Dynamic Main Title */}
      <Typography 
        variant="h4" 
        sx={{ 
          fontSize: "50px",
          fontWeight: 'bold', 
          mb: 2, 
          fontFamily: '"MO_Nawel", sans-serif',
          color: isDarkMode ? '#ffffff' : '#fff'
        }}
      >
        {mainTitle} {/* Dynamic Main Title */}
      </Typography>

      {/* First Slider */}
      <Slider {...settings}>
        {sliderOne.map((category, index) => (
          <div key={index}>
            <Paper
              elevation={3}
              sx={{
                padding: '20px',
                textAlign: 'center',
                position: 'relative',
                overflow: 'hidden',
                cursor: 'pointer',
                backgroundColor: isDarkMode ? '#1e1e1e' : '#f5f5f5',
                color: isDarkMode ? '#ffffff' : '#000000',
                margin: '0 10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'background-color 0.3s',
                height: '100%',
              }}
            >
              <img 
                src={`https://backendforerpsystem.zbooma.com/${category.image}`} 
                alt={category.name} 
                style={{ 
                  width: '100px', 
                  height: '100px',
                  objectFit: 'contain',
                  marginBottom: '10px',
                  filter: isDarkMode ? 'invert(1)' : 'none' 
                }} 
              />
              <Typography 
                variant="h6" 
                sx={{ 
                  fontSize: "25px",
                  fontFamily: '"MO_Nawel", sans-serif',
                  color: isDarkMode ? '#ffffff' : '#000000',
                  textAlign: 'center',
                }}
              >
                {category.name}
              </Typography>
            </Paper>
          </div>
        ))}
      </Slider>

      {/* Second Slider with Inverse Direction */}
      <Slider style={{ marginTop: "20px" }} {...inverseSettings}>
        {sliderTwo.map((category, index) => (
          <div key={index}>
            <Paper
              elevation={3}
              sx={{
                padding: '20px',
                textAlign: 'center',
                position: 'relative',
                overflow: 'hidden',
                cursor: 'pointer',
                backgroundColor: isDarkMode ? '#1e1e1e' : '#f5f5f5',
                color: isDarkMode ? '#ffffff' : '#000000',
                margin: '0 10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'background-color 0.3s',
                height: '100%',
              }}
            >
              <img 
                src={`https://backendforerpsystem.zbooma.com/${category.image}`} 
                alt={category.name} 
                style={{ 
                  width: '100px', 
                  height: '100px',
                  objectFit: 'contain',
                  marginBottom: '10px',
                  filter: isDarkMode ? 'invert(1)' : 'none' 
                }} 
              />
              <Typography 
                variant="h6" 
                sx={{ 
                  fontSize: "25px",
                  fontFamily: '"MO_Nawel", sans-serif',
                  color: isDarkMode ? '#ffffff' : '#000000',
                  textAlign: 'center',
                }}
              >
                {category.name}
              </Typography>
            </Paper>
          </div>
        ))}
      </Slider>
    </Box>
  );
};

export default OurServices;
