// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    const adminToken = localStorage.getItem('adminToken');

    if (token) {
      setIsAuthenticated(true);
    }
    if (adminToken) {
      setIsAdminAuthenticated(true); // Set isAdminAuthenticated to true if adminToken exists
    }
  }, []);

  const login = (token) => {
    localStorage.setItem('userToken', token);
    setIsAuthenticated(true);
  };

  const adminLogin = (token) => {
    localStorage.setItem('adminToken', token);
    setIsAdminAuthenticated(true); // Set admin authentication state
  };

  const logout = () => {
    localStorage.removeItem('userToken');
    setIsAuthenticated(false);
  };

  const adminLogout = () => {
    localStorage.removeItem('adminToken');
    setIsAdminAuthenticated(false); // Remove admin authentication state
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, isAdminAuthenticated, login, adminLogin, logout, adminLogout }}>
      {children}
    </AuthContext.Provider>
  );
};
