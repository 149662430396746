import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Button, IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const PortfolioManagement = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [previewHeaderImage, setPreviewHeaderImage] = useState(null);
  const [previewPortfolioImage, setPreviewPortfolioImage] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    subtitle: '',
    button_text: '',
    header_button_text: '',
    header_image: null,
    portfolio_image: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          'https://backendforerpsystem.zbooma.com/get_portfolio_content.php'
        );
        setData(response.data.data[0]);
        setFormData({
          title: response.data.data[0].title,
          subtitle: response.data.data[0].subtitle,
          button_text: response.data.data[0].button_text,
          header_button_text: response.data.data[0].header_button_text,
        });
        setLoading(false);
      } catch (error) {
        setError('Error fetching data.');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (name === 'header_image') {
      setFormData({ ...formData, header_image: file });
      setPreviewHeaderImage(URL.createObjectURL(file));
    } else if (name === 'portfolio_image') {
      setFormData({ ...formData, portfolio_image: file });
      setPreviewPortfolioImage(URL.createObjectURL(file));
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to update the content!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, update it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updateFormData = new FormData();
        updateFormData.append('title', formData.title);
        updateFormData.append('subtitle', formData.subtitle);
        updateFormData.append('button_text', formData.button_text);
        updateFormData.append('header_button_text', formData.header_button_text);
  
        // Only append images if they are selected
        if (formData.header_image) {
          updateFormData.append('header_image', formData.header_image);
        }
        if (formData.portfolio_image) {
          updateFormData.append('portfolio_image', formData.portfolio_image);
        }
  
        try {
          const response = await axios.post(
            'https://backendforerpsystem.zbooma.com/get_portfolio_content.php',
            updateFormData,
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            }
          );
  
          console.log(response.data); // Check the response from the server
          
          // Assuming response.data.success is true
          if (response.data.success) {
            Swal.fire('Updated!', 'The content has been updated.', 'success');
            
            // Update the state immediately to reflect changes
            setData({
              ...data,
              title: formData.title,
              subtitle: formData.subtitle,
              button_text: formData.button_text,
              header_button_text: formData.header_button_text,
              // If new images were uploaded, update the image URLs
              header_image_url: formData.header_image ? URL.createObjectURL(formData.header_image) : data.header_image_url,
              portfolio_image_url: formData.portfolio_image ? URL.createObjectURL(formData.portfolio_image) : data.portfolio_image_url,
            });
  
            setIsEditing(false); // Exit edit mode after successful submission
          }
        } catch (error) {
          console.error('Error updating content:', error);
          Swal.fire('Error', 'Something went wrong!', 'error');
        }
      }
    });
  };
  
  

  if (loading) {
    return <div style={styles.loading}>Loading...</div>;
  }

  if (error) {
    return <div style={styles.error}>{error}</div>;
  }

  return (
    <div style={styles.portfolioContainer}>
      <header style={styles.header}>
        <div style={styles.logo}>
          <img
            src={`https://backendforerpsystem.zbooma.com${data.header_image_url}`}
            alt="Logo"
            style={styles.logoImage}
          />
        </div>
        <div style={styles.buttonGroup}>
          <Button style={styles.headerButton}>
            {data.header_button_text}
          </Button>
          <Button style={styles.editButton} onClick={handleEditToggle}>
            {isEditing ? 'Cancel' : 'Edit'}
          </Button>
        </div>
      </header>

      {isEditing ? (
        <form onSubmit={handleSubmit} style={styles.form}>
          <div>
            <label>Title:</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              style={styles.inputField}
            />
          </div>
          <div>
            <label>Subtitle:</label>
            <input
              type="text"
              name="subtitle"
              value={formData.subtitle}
              onChange={handleInputChange}
              style={styles.inputField}
            />
          </div>
          <div>
            <label>Button Text:</label>
            <input
              type="text"
              name="button_text"
              value={formData.button_text}
              onChange={handleInputChange}
              style={styles.inputField}
            />
          </div>
          <div>
            <label>Header Button Text:</label>
            <input
              type="text"
              name="header_button_text"
              value={formData.header_button_text}
              onChange={handleInputChange}
              style={styles.inputField}
            />
          </div>

          {/* Header Image Upload with Preview */}
          <div style={styles.imageUploadContainer}>
            <label>Header Image:</label>
            <IconButton
              color="primary"
              aria-label="upload header image"
              component="label"
            >
              <input
                hidden
                type="file"
                name="header_image"
                accept="image/*"
                onChange={handleImageChange}
              />
              <CloudUploadIcon />
            </IconButton>
            {previewHeaderImage ? (
              <img
                src={previewHeaderImage}
                alt="Header Preview"
                style={styles.previewImage}
              />
            ) : (
              <img
                src={`https://backendforerpsystem.zbooma.com${data.header_image_url}`}
                alt="Header"
                style={styles.previewImage}
              />
            )}
          </div>

          {/* Portfolio Image Upload with Preview */}
          <div style={styles.imageUploadContainer}>
            <label>Portfolio Image:</label>
            <IconButton
              color="primary"
              aria-label="upload portfolio image"
              component="label"
            >
              <input
                hidden
                type="file"
                name="portfolio_image"
                accept="image/*"
                onChange={handleImageChange}
              />
              <CloudUploadIcon />
            </IconButton>
            {previewPortfolioImage ? (
              <img
                src={previewPortfolioImage}
                alt="Portfolio Preview"
                style={styles.previewImage}
              />
            ) : (
              <img
                src={`https://backendforerpsystem.zbooma.com${data.portfolio_image_url}`}
                alt="Portfolio"
                style={styles.previewImage}
              />
            )}
          </div>

          <Button type="submit" variant="contained" color="success" style={styles.saveButton}>
            Save
          </Button>
        </form>
      ) : (
        <section style={styles.contentSection}>
          <div style={styles.textDiv}>
            <h1 style={styles.title}>{data.title}</h1>
            <p style={styles.subtitle}>{data.subtitle}</p>
            <Button variant="contained" color="primary" style={styles.contactButton}>
              {data.button_text}
            </Button>
          </div>
          <div style={styles.imageDiv}>
            <img
              src={`https://backendforerpsystem.zbooma.com${data.portfolio_image_url}`}
              alt="Portfolio"
              style={styles.portfolioImage}
            />
          </div>
        </section>
      )}
    </div>
  );
};

// Styles
const styles = {
  portfolioContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    maxWidth: '1200px',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: '#000000',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '40px',
  },
  logo: {
    cursor: 'pointer',
  },
  logoImage: {
    width: '80px',
    height: '102px',
  },
  buttonGroup: {
    display: 'flex',
    gap: '10px',
  },
  headerButton: {
    backgroundColor: '#007bff',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '5px',
  },
  editButton: {
    backgroundColor: '#28a745',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '5px',
  },
  contentSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
  },
  textDiv: {
    flex: 1,
    paddingRight: '20px',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#333',
  },
  subtitle: {
    fontSize: '18px',
    color: '#555',
    marginBottom: '20px',
  },
  contactButton: {
    backgroundColor: '#007bff',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '5px',
  },
  imageDiv: {
    flex: 1,
  },
  portfolioImage: {
    maxWidth: '100%',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  inputField: {
    width: '100%',
    padding: '8px',
    margin: '8px 0',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  saveButton: {
    padding: '10px 20px',
    marginTop: '20px',
    backgroundColor: '#28a745',
    color: 'white',
  },
  imageUploadContainer: {
    margin: '20px 0',
  },
  previewImage: {
    width: '150px',
    height: 'auto',
    marginTop: '10px',
    borderRadius: '5px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  loading: {
    fontSize: '24px',
    color: '#007bff',
  },
  error: {
    fontSize: '24px',
    color: 'red',
  },
};

export default PortfolioManagement;
