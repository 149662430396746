import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography,
  Box,
  CardHeader,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton
} from '@mui/material';
import { Email, Phone, Web, Home, CheckCircle, HourglassEmpty, PlayCircle, InsertDriveFile, Visibility } from '@mui/icons-material';
import swal from 'sweetalert';

const Dashboard = () => {
  const { username } = useParams();
  const [userData, setUserData] = useState(null);
  const [projectSteps, setProjectSteps] = useState([]);
  const [meetingTimes, setMeetingTimes] = useState([]);
  const [bookedTimes, setBookedTimes] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bookingDialogOpen, setBookingDialogOpen] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('http://ertaqybackend.ertaqyagency-e.com/get_user_data.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username }),
        });

        const result = await response.json();
        if (response.ok) {
          setUserData(result.data);
          fetchProjectSteps(result.data.id);
          fetchFreeMeetingTimes();
          fetchBookedMeetingTimes(username);
          fetchUserContracts(result.data.id);
        } else {
          console.error('Error fetching user data:', result.message);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchProjectSteps = async (userId) => {
      try {
        const response = await fetch(`http://ertaqybackend.ertaqyagency-e.com/get_project_steps.php?user_id=${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const result = await response.json();
        if (response.ok) {
          setProjectSteps(result.data);
        } else {
          console.error('Error fetching project steps:', result.message);
        }
      } catch (error) {
        console.error('Error fetching project steps:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchFreeMeetingTimes = async () => {
      try {
        const response = await fetch('http://ertaqybackend.ertaqyagency-e.com/get_free_meeting_times.php', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const result = await response.json();
        if (response.ok) {
          setMeetingTimes(result.data);
        } else {
          console.error('Error fetching free meeting times:', result.message);
        }
      } catch (error) {
        console.error('Error fetching free meeting times:', error);
      }
    };

    const fetchBookedMeetingTimes = async (username) => {
      try {
        const response = await fetch('http://ertaqybackend.ertaqyagency-e.com/get_user_booked_times.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username })
        });

        const result = await response.json();
        if (response.ok) {
          setBookedTimes(result.data);
        } else {
          console.error('Error fetching booked meeting times:', result.message);
        }
      } catch (error) {
        console.error('Error fetching booked meeting times:', error);
      }
    };

    const fetchUserContracts = async (userId) => {
      try {
        const response = await fetch(`http://ertaqybackend.ertaqyagency-e.com/get_user_contracts.php?user_id=${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const result = await response.json();
        if (response.ok) {
          setContracts(result.data);
        } else {
          console.error('Error fetching user contracts:', result.message);
        }
      } catch (error) {
        console.error('Error fetching user contracts:', error);
      }
    };

    fetchUserData();
  }, [username]);

  const handleBookingOpen = (meeting) => {
    setSelectedMeeting(meeting);
    setBookingDialogOpen(true);
  };

  const handleBookingClose = () => {
    setBookingDialogOpen(false);
    setSelectedMeeting(null);
  };

  const handleBookMeeting = async () => {
    if (!selectedMeeting) return;

    try {
      const response = await fetch('http://ertaqybackend.ertaqyagency-e.com/book_meeting_time.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ meeting_id: selectedMeeting.id, username: username }),
      });

      const result = await response.json();
      if (response.ok) {
        swal("نجاح", "تم حجز الموعد بنجاح", "success");
        setMeetingTimes((prev) =>
          Object.keys(prev).reduce((acc, day) => {
            acc[day] = prev[day].filter((time) => time.id !== selectedMeeting.id);
            return acc;
          }, {})
        );
        setBookedTimes((prev) => [
          ...prev,
          {
            day: selectedMeeting.day,
            start_time: selectedMeeting.start_time,
            end_time: selectedMeeting.end_time,
          },
        ]);
        handleBookingClose();
      } else {
        swal("خطأ", result.message || "حدث خطأ أثناء حجز الموعد", "error");
        console.error('Error booking meeting time:', result.message);
      }
    } catch (error) {
      swal("خطأ", "حدث خطأ أثناء الاتصال بالخادم", "error");
      console.error('Error booking meeting time:', error);
    }
  };

  const handlePreview = async (contractId) => {
    console.log('Previewing contract with ID:', contractId);
    try {
      const response = await fetch(`http://ertaqybackend.ertaqyagency-e.com/download_contract.php?file_id=${contractId}`);
      const result = await response.json();
      if (response.ok) {
        setPreviewUrl(result.url);
        setPreviewDialogOpen(true);
      } else {
        swal("خطأ", result.message || "حدث خطأ أثناء استعراض الملف", "error");
        console.error('Error previewing file:', result.message);
      }
    } catch (error) {
      swal("خطأ", "حدث خطأ أثناء الاتصال بالخادم", "error");
      console.error('Error previewing file:', error);
    }
  };

  const handleDownload = (contractId) => {
    console.log('Downloading contract with ID:', contractId);
    const url = `http://ertaqybackend.ertaqyagency-e.com/download_contract.php?file_id=${contractId}`;
    window.location.href = url;
  };

  const handlePreviewClose = () => {
    setPreviewDialogOpen(false);
    setPreviewUrl('');
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', fontFamily: 'Cairo, sans-serif' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!userData) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', fontFamily: 'Cairo, sans-serif' }}>
        <Typography variant="h6">لا يوجد بيانات لعرضها</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#3f51b5',
          padding: '20px',
          color: 'white',
          textAlign: 'center',
          fontFamily: 'Cairo, sans-serif',
        }}
      >
        <Typography variant="h4" sx={{ fontFamily: 'Cairo, sans-serif' }}>
          مرحباً، {userData.name}
        </Typography>
      </Box>
      <Box
        sx={{
          fontFamily: 'Cairo, sans-serif',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px',
          maxWidth: '1200px',
          margin: '50px auto',
          backgroundColor: '#f9f9f9',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          direction: 'rtl',
          '@media (max-width: 600px)': {
            padding: '10px',
            margin: '20px auto',
          }
        }}
      >
        <Typography variant="h4" component="h2" gutterBottom sx={{ fontFamily: 'Cairo, sans-serif' }}>
          بيانات المستخدم
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ minHeight: '150px', fontFamily: 'Cairo, sans-serif' }}>
              <CardContent>
                <Avatar sx={{ bgcolor: '#3f51b5', width: 56, height: 56, mb: 2 }}>
                  <Email />
                </Avatar>
                <Typography variant="h6" sx={{ fontFamily: 'Cairo, sans-serif' }}>البريد الإلكتروني</Typography>
                <Typography variant="body1" sx={{ fontFamily: 'Cairo, sans-serif' }}>{userData.email}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ minHeight: '150px', fontFamily: 'Cairo, sans-serif' }}>
              <CardContent>
                <Avatar sx={{ bgcolor: '#3f51b5', width: 56, height: 56, mb: 2 }}>
                  <Phone />
                </Avatar>
                <Typography variant="h6" sx={{ fontFamily: 'Cairo, sans-serif' }}>الهاتف</Typography>
                <Typography variant="body1" sx={{ fontFamily: 'Cairo, sans-serif' }}>{userData.phone}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ minHeight: '150px', fontFamily: 'Cairo, sans-serif' }}>
              <CardContent>
                <Avatar sx={{ bgcolor: '#3f51b5', width: 56, height: 56, mb: 2 }}>
                  <Web />
                </Avatar>
                <Typography variant="h6" sx={{ fontFamily: 'Cairo, sans-serif' }}>رابط الموقع</Typography>
                <Typography variant="body1" sx={{ fontFamily: 'Cairo, sans-serif' }}>{userData.website}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ minHeight: '150px', fontFamily: 'Cairo, sans-serif' }}>
              <CardContent>
                <Avatar sx={{ bgcolor: '#3f51b5', width: 56, height: 56, mb: 2 }}>
                  <Home />
                </Avatar>
                <Typography variant="h6" sx={{ fontFamily: 'Cairo, sans-serif' }}>العنوان</Typography>
                <Typography variant="body1" sx={{ fontFamily: 'Cairo, sans-serif' }}>{userData.address}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Typography variant="h4" component="h2" gutterBottom sx={{ fontFamily: 'Cairo, sans-serif', marginTop: '40px' }}>
          تفاصيل المشروع الخاص بك
        </Typography>
        <Grid container spacing={4}>
          {projectSteps.map((step) => (
            <Grid item xs={12} sm={6} md={4} key={step.id}>
              <Card sx={{ minHeight: '150px', fontFamily: 'Cairo, sans-serif' }}>
                <CardHeader
                  avatar={
                    step.status === 'completed' ? (
                      <CheckCircle sx={{ color: 'green' }} />
                    ) : step.status === 'pending' ? (
                      <HourglassEmpty sx={{ color: 'orange' }} />
                    ) : (
                      <PlayCircle sx={{ color: 'blue' }} />
                    )
                  }
                  title={<Typography sx={{ fontFamily: 'Cairo, sans-serif' }}>{step.step_name}</Typography>}
                />
                <CardContent>
                  <Typography variant="body1" sx={{ fontFamily: 'Cairo, sans-serif' }}>
                    {step.comment}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Typography variant="h4" component="h2" gutterBottom sx={{ fontFamily: 'Cairo, sans-serif', marginTop: '40px' }}>
          الأوقات المتاحة
        </Typography>
        <Grid container spacing={4}>
          {Object.entries(meetingTimes).map(([day, times]) => (
            <Grid item xs={12} md={6} key={day}>
              <Card sx={{ minHeight: '150px', fontFamily: 'Cairo, sans-serif' }}>
                <CardContent>
                  <Typography variant="h6" sx={{ fontFamily: 'Cairo, sans-serif' }}>{day}</Typography>
                  <Box>
                    {times.map((time, index) => (
                      <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography sx={{ fontFamily: 'Cairo, sans-serif' }}>
                          {time.start_time} - {time.end_time}
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ fontFamily: 'Cairo, sans-serif' }}
                          onClick={() => handleBookingOpen(time)}
                        >
                          حجز
                        </Button>
                      </Box>
                    ))}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Typography variant="h4" component="h2" gutterBottom sx={{ fontFamily: 'Cairo, sans-serif', marginTop: '40px' }}>
          الأوقات المحجوزة الخاصة بك
        </Typography>
        <Grid container spacing={4}>
          {bookedTimes.length > 0 ? (
            bookedTimes.map((time, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card sx={{ minHeight: '150px', fontFamily: 'Cairo, sans-serif' }}>
                  <CardContent>
                    <Typography variant="h6" sx={{ fontFamily: 'Cairo, sans-serif' }}>{time.day}</Typography>
                    <Typography sx={{ fontFamily: 'Cairo, sans-serif' }}>
                      {time.start_time} - {time.end_time}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography variant="body1" sx={{ fontFamily: 'Cairo, sans-serif' }}>
              لم يتم حجز بعد
            </Typography>
          )}
        </Grid>
        <Typography variant="h4" component="h2" gutterBottom sx={{ fontFamily: 'Cairo, sans-serif', marginTop: '40px' }}>
          العقودات الخاصة بك
        </Typography>
        <Grid container spacing={4}>
          {contracts.length > 0 ? (
            contracts.map((contract) => (
              <Grid item xs={12} md={6} key={contract.id}>
                <Card sx={{ minHeight: '150px', fontFamily: 'Cairo, sans-serif' }}>
                  <CardContent>
                    <Avatar sx={{ bgcolor: '#3f51b5', width: 56, height: 56, mb: 2 }}>
                      <InsertDriveFile />
                    </Avatar>
                    <Typography variant="h6" sx={{ fontFamily: 'Cairo, sans-serif' }}>
                      {`عقد رقم ${contract.id}`}
                    </Typography>
                    <Typography sx={{ fontFamily: 'Cairo, sans-serif' }}>
                      {`تاريخ الرفع: ${contract.upload_date}`}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ fontFamily: 'Cairo, sans-serif', mt: 1 }}
                        onClick={() => handlePreview(contract.id)}
                      >
                          تنزيل العقد
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography variant="body1" sx={{ fontFamily: 'Cairo, sans-serif' }}>
              لا يوجد عقودات متاحة
            </Typography>
          )}
        </Grid>
      </Box>
      <Dialog open={bookingDialogOpen} onClose={handleBookingClose}>
        <DialogTitle sx={{ fontFamily: 'Cairo, sans-serif' }}>تأكيد الحجز</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontFamily: 'Cairo, sans-serif' }}>
            هل أنت متأكد أنك تريد حجز الموعد التالي؟
          </DialogContentText>
          <DialogContentText sx={{ fontFamily: 'Cairo, sans-serif' }}>
            {selectedMeeting && `${selectedMeeting.start_time} - ${selectedMeeting.end_time}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBookingClose} sx={{ fontFamily: 'Cairo, sans-serif' }}>إلغاء</Button>
          <Button onClick={handleBookMeeting} sx={{ fontFamily: 'Cairo, sans-serif' }}>تأكيد</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={previewDialogOpen} onClose={handlePreviewClose}>
        <DialogTitle sx={{ fontFamily: 'Cairo, sans-serif' }}>استعراض العقد</DialogTitle>
        <DialogContent>
          <iframe src={previewUrl} style={{ width: '100%', height: '500px' }} title="File Preview"></iframe>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePreviewClose} sx={{ fontFamily: 'Cairo, sans-serif' }}>إغلاق</Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          backgroundColor: '#3f51b5',
          padding: '10px',
          color: 'white',
          textAlign: 'center',
          marginTop: 'auto',
          fontFamily: 'Cairo, sans-serif',
        }}
      >
        <Typography variant="body2" sx={{ fontFamily: 'Cairo, sans-serif' }}>
          © 2024 شركتي. جميع الحقوق محفوظة.
        </Typography>
      </Box>
    </>
  );
};

export default Dashboard;
