import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import backgroundImage from '../../images/Rectangle88.png';
import characterImage from '../../images/Image27.png';
import InfiniteCarousel from '../ToolsLogos/ToolsLogos';

const AboutSection = () => {
  return (
    <div style={{marginTop:"50px",marginBottom:"50px"}}>
      <InfiniteCarousel/>
    </div>
    // <Box
    //   sx={{
    //     display: 'flex',
    //     flexDirection: { xs: 'column', md: 'row' }, // Stack vertically on small screens, keep row on desktop
    //     justifyContent: 'space-around',
    //     alignItems: 'center',
    //     backgroundColor: '#30355b',
    //     padding: { xs: '20px', md: '40px' }, // Adjust padding for small screens
    //     borderRadius: '10px',
    //     position: 'relative',
    //     marginTop: '50px',
    //   }}
    // >
    //   {/* Left Side: Image with character hovering over background */}
    //   <Box
    //     sx={{
    //       position: 'relative',
    //       width: { xs: '100%', md: '30%' }, // Full width on small screens, 30% on medium and larger
    //       height: { xs: 'auto', md: '620px' }, // Auto height on small screens, fixed height on larger screens
    //       marginBottom: { xs: '20px', md: '0' }, // Margin bottom on small screens, none on desktop
    //       marginTop: { xs: '100px', md: '0' }, // Add margin top on small screens
    //       textAlign: 'center', // Center the image on small screens
    //     }}
    //   >
    //     {/* Background Image */}
    //     <Box
    //       sx={{
    //         backgroundImage: `url(${backgroundImage})`,
    //         backgroundSize: 'contain',
    //         backgroundRepeat: 'no-repeat',
    //         backgroundPosition: 'center',
    //         borderRadius: '20px',
    //         width: { xs: '50%', md: '100%' }, // Smaller width on small screens
    //         height: { xs: '200px', md: '100%' }, // Smaller height on small screens
    //         position: 'relative',
    //         margin: '0 auto', // Center the background box on small screens
    //       }}
    //     >
    //       {/* Character Image */}
    //       <img
    //         src={characterImage}
    //         alt="Founder"
    //         style={{
    //           position: 'absolute',
    //           bottom: '10px', // Adjusted bottom position for small screens
    //           left: '50%',
    //           transform: 'translateX(-50%)',
    //           maxWidth: { xs: '80%', md: '100%' }, // Smaller image on small screens
    //           height: 'auto',
    //           zIndex: 1,
    //         }}
    //       />
    //     </Box>
    //   </Box>

    //   {/* Right Side: Text Content */}
    //   <Box
    //     sx={{
    //       width: { xs: '100%', md: '65%' }, // Full width on small screens, 65% on medium and larger
    //       color: 'white',
    //       textAlign: 'right',
    //       fontFamily: '"MO_Nawel", sans-serif',
    //     }}
    //   >
    //     <Typography
    //       variant="h5"
    //       sx={{
    //         fontFamily: '"MO_Nawel", sans-serif',
    //         fontSize: { xs: '28px', md: '40px' }, // Adjust font size for small screens
    //         fontWeight: 'bold',
    //         marginBottom: '20px',
    //       }}
    //     >
    //       كــلمـة المؤســس
    //       <br />
    //       المــدير الـعـام |
    //     </Typography>
    //     <Typography
    //       variant="body1"
    //       sx={{
    //         fontFamily: '"MO_Nawel", sans-serif',
    //         fontSize: { xs: '18px', md: '30px' }, // Adjust font size for small screens
    //         fontWeight: '400',
    //         lineHeight: '1.6',
    //         marginBottom: '20px',
    //       }}
    //       dangerouslySetInnerHTML={{
    //         __html: `
    //           موقعك الإلكتروني هو واجهة عملك الرقمية، وهو أكثر من مجرد بطاقة عمل<br/>
    //           في عالم اليوم الرقمي، أصبح وجـود موقع إلكـتـروني ضــرورة ملحـة<br/>
    //           لأي عمل، بغض النظر عن حجمه أو نوعه. إليك أهم الأسباب التي تفضلك للحصول<br/>
    //           على خدمة تصميم موقع إلكتروني احترافي`,
    //       }}
    //     />
    //     <Button
    //       variant="contained"
    //       sx={{
    //         textAlign: 'right',
    //         backgroundColor: '#42aaa6',
    //         color: 'white',
    //         borderRadius: '20px',
    //         padding: { xs: '8px 16px', md: '10px 20px' }, // Adjust padding for small screens
    //         fontFamily: '"MO_Nawel", sans-serif',
    //         fontSize: { xs: '18px', md: '25px' }, // Adjust font size for small screens
    //         fontWeight: '500',
    //       }}
    //       startIcon={<ArrowBackIcon sx={{ fontSize: { xs: '20px', md: '30px' } }} />} // Adjust icon size for small screens
    //     >
    //       المزيد
    //     </Button>
    //   </Box>
    // </Box>
  );
};

export default AboutSection;
