import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button, Typography, Box } from '@mui/material';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Components/AuthContext';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import { ThemeContext } from '../../Components/ThemeContext';

const Login = () => {
  const { themeMode } = useContext(ThemeContext); // Access the theme mode
  const isDarkMode = themeMode === 'dark'; // Determine if dark mode is active
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const response = await fetch('http://ertaqybackend.ertaqyagency-e.com/login_user.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (response.ok) {
        login(result.token); // Save the token
        swal("نجاح", "تم تسجيل الدخول بنجاح", "success").then(() => {
          navigate(`/dashboard/${data.username}`); // Navigate to the dashboard with username
        });
      } else {
        swal("خطأ", result.message || "حدث خطأ أثناء تسجيل الدخول", "error");
      }
    } catch (error) {
      swal("خطأ", "حدث خطأ أثناء الاتصال بالخادم", "error");
    }
  };

  return (
    <div
      style={{
        backgroundColor: isDarkMode ? '#121212' : '#f5f5f5', // Dark gray for dark mode, light gray for light mode
        minHeight: '100vh',
        margin: 0,
        padding: 0
      }}
    >
      <NavBar />
      <Box
        sx={{
          fontFamily: 'Cairo, sans-serif',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px',
          maxWidth: '500px',
          margin: '50px auto',
          backgroundColor: isDarkMode ? '#1e1e1e' : '#ffffff', // Darker background for dark mode
          color: isDarkMode ? '#e0e0e0' : '#000000', // Light text color for dark mode
          borderRadius: '10px',
          boxShadow: isDarkMode ? '0 4px 8px rgba(255, 255, 255, 0.2)' : '0 4px 8px rgba(0, 0, 0, 0.1)',
          direction: 'rtl',
          '@media (max-width: 600px)': {
            padding: '10px',
            margin: '20px auto',
          }
        }}
      >
        <Typography
          style={{ fontFamily: 'Cairo, sans-serif', color: isDarkMode ? '#ffffff' : '#000000' }} // Title color for dark mode
          variant="h4"
          component="h2"
          gutterBottom
        >
          تسجيل الدخول
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <TextField
            style={{ fontFamily: 'Cairo, sans-serif' }}
            label="اسم المستخدم"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register('username', { required: 'هذا الحقل مطلوب' })}
            error={!!errors.username}
            helperText={errors.username ? errors.username.message : ''}
            InputLabelProps={{ style: { color: isDarkMode ? '#ffffff' : '#000000' } }} // Label color for dark mode
            inputProps={{ style: { color: isDarkMode ? '#ffffff' : '#000000' } }} // Input text color for dark mode
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: isDarkMode ? '#333333' : '#ffffff', // Input background color
                '& fieldset': {
                  borderColor: isDarkMode ? '#555555' : '#cccccc', // Border color for dark mode
                },
              },
              '& .MuiFormLabel-root': {
                color: isDarkMode ? '#ffffff' : '#000000', // Label color for dark mode
              },
              '& .MuiInputBase-input': {
                color: isDarkMode ? '#ffffff' : '#000000', // Input text color
              },
            }}
          />
          <TextField
            style={{ fontFamily: 'Cairo, sans-serif' }}
            label="كلمة المرور"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register('password', { required: 'هذا الحقل مطلوب' })}
            error={!!errors.password}
            helperText={errors.password ? errors.password.message : ''}
            InputLabelProps={{ style: { color: isDarkMode ? '#ffffff' : '#000000' } }} // Label color for dark mode
            inputProps={{ style: { color: isDarkMode ? '#ffffff' : '#000000' } }} // Input text color for dark mode
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: isDarkMode ? '#333333' : '#ffffff', // Input background color
                '& fieldset': {
                  borderColor: isDarkMode ? '#555555' : '#cccccc', // Border color for dark mode
                },
              },
              '& .MuiFormLabel-root': {
                color: isDarkMode ? '#ffffff' : '#000000', // Label color for dark mode
              },
              '& .MuiInputBase-input': {
                color: isDarkMode ? '#ffffff' : '#000000', // Input text color
              },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              marginTop: '16px',
              fontFamily: 'Cairo, sans-serif',
              backgroundColor: isDarkMode ? '#616161' : '#007bff', // Button color for dark mode
              color: '#ffffff',
              '&:hover': {
                backgroundColor: isDarkMode ? '#757575' : '#0056b3', // Button hover color for dark mode
              },
            }}
          >
            تسجيل الدخول
          </Button>
        </form>
      </Box>
      <Footer />
    </div>
  );
}

export default Login;
