import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, TextField, Typography, Card, CardContent, CardMedia, Link, Box, Paper, Chip, List, ListItem, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import NavBar from '../../Components/NavBar/NavBar';
import image from "../../images/MaskGroup24.png"; // Ensure this path is correct

const CustomSearchBox = ({ searchTerm, handleSearch }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        direction: "ltr",
        border: "1px solid #eaebee",
        borderRadius: "5px",
      }}
    >
      <Paper
        elevation={1}
        sx={{
          display: 'flex',
          borderRadius: '25px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
          width:"99%"
        }}
      >
        <IconButton
          sx={{
            backgroundColor: '#1A73E8',
            borderRadius: '15px 0 0 15px',
            padding: '12px',
          }}
        >
          <SearchIcon sx={{ color: '#fff' }} />
        </IconButton>

        <TextField
          value={searchTerm}
          onChange={handleSearch}
          style={{ textAlign: "right" }}
          placeholder="ابحث هنا"
          variant="outlined"
          fullWidth
          InputProps={{
            sx: {
              borderRadius: '0 15px 15px 0',
              padding: 0,
            },
            disableUnderline: true,
          }}
        />
      </Paper>
    </Box>
  );
};

const ArticlesPage = () => {
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('الكل');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  // Fetch articles from API when component mounts
  useEffect(() => {
    axios.get('https://backendforerpsystem.zbooma.com/articles.php')
      .then(response => {
        setArticles(response.data);
        setFilteredArticles(response.data);

        // Extract unique categories and add "الكل" option
        const uniqueCategories = ['الكل', ...new Set(response.data.map(article => article.category))];
        setCategories(uniqueCategories);
        setLoading(false); // Data loaded, hide the spinner
      })
      .catch(error => {
        console.error("Error fetching articles:", error);
        setLoading(false); // In case of error, stop loading spinner
      });
  }, []);

  // Handle search input change
  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);

    const filtered = articles.filter(article =>
      article.title.toLowerCase().includes(searchValue.toLowerCase()) &&
      (selectedCategory === 'الكل' || article.category === selectedCategory)
    );
    setFilteredArticles(filtered);
  };

  // Handle category click
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    const filtered = articles.filter(article =>
      (category === 'الكل' || article.category === category) &&
      article.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredArticles(filtered);
  };

  // Handle card click
  const handleCardClick = (article) => {
    setSelectedArticle(article); // Set the selected article
    setOpenDialog(true); // Open the dialog
  };

  // Close the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedArticle(null);
  };

  // Get the last 3 articles sorted by date (created_at)
  const latestArticles = [...filteredArticles]
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort by date descending
    .slice(0, 3); // Take the last 3 articles

  return (
    <>
      <NavBar />
      <Box
        sx={{
          height: { xs: '40vh', md: '65vh' },
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          boxShadow: 'inset 0 0 0 1000px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: 'white',
            fontSize: { xs: '50px', md: '180px' },
            fontWeight: '500',
            textAlign: 'center',
            fontFamily: '"MO_Nawel", sans-serif',
          }}
        >
          المدونة
        </Typography>
      </Box>
      <Box
        sx={{
          padding: '20px',
          direction: 'rtl',
          maxWidth: '1200px',
          margin: 'auto',
        }}
      >
        {/* Display spinner while loading */}
                {loading ? (
       <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
            <CircularProgress />
                        </Box>
        ) : (                       


          
          <Grid container spacing={4}>
            {/* Right Column (Articles) */}
            <Grid item xs={12} md={8}>
              <Grid container spacing={3}>
                {/* Render filtered articles dynamically */}
                {filteredArticles.map((article) => (
                  <Grid item xs={12} key={article.id}>
                    <Card
                      sx={{
                        borderRadius: '15px',
                        cursor:"pointer",
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        marginBottom: '20px',
                        overflow: 'hidden',
                        transition: 'transform 0.3s, box-shadow 0.3s',
                        '&:hover': {
                          transform: 'scale(1.03)',
                          boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)',
                        }
                      }}
                      onClick={() => handleCardClick(article)} // Open dialog on card click
                    >
                      {/* Image at the top */}
                      <CardMedia
                        component="img"
                        image={article.image}
                        alt={article.title}
                        sx={{
                          height: 300,
                          width: '100%',
                          objectFit: 'cover',
                          borderBottom: '4px solid #1A73E8',
                          borderRadius: '5px',
                          marginTop: "20px"
                        }}
                      />

                      {/* Content below the image */}
                      <CardContent sx={{ padding: '20px' }}>
                        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', fontFamily: 'Cairo, sans-serif' }}>
                          {article.title}
                        </Typography>
                        {/* Show only 150 characters */}
                        <Typography variant="body2" color="text.secondary" sx={{ marginBottom: '10px', fontFamily: 'Cairo, sans-serif' }}>
                          {article.description.length > 150
                            ? `${article.description.substring(0, 150)}...`
                            : article.description}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                          <CalendarTodayIcon fontSize="small" sx={{ marginLeft: '5px' }} />
                          <Typography variant="caption" color="text.secondary" sx={{ fontFamily: 'Cairo, sans-serif' }}>
                            {article.created_at}
                          </Typography>
                        </Box>
                        <Link
                          onClick={() => handleCardClick(article)} // Open dialog on link click
                          underline="hover"
                          sx={{
                            fontWeight: 'bold',
                            textDecoration: 'none',
                            color: '#1976d2',
                            fontFamily: 'Cairo, sans-serif',
                            cursor: 'pointer'
                          }}
                        >
                          قراءة التفاصيل
                        </Link>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {/* Left Column (Search box, Latest Articles, Categories) */}
            <Grid item xs={12} md={4}>
              {/* Custom Search box */}
              <CustomSearchBox searchTerm={searchTerm} handleSearch={handleSearch} />

              {/* Latest Articles */}
              <Paper elevation={3} sx={{ padding: '20px', marginBottom: '20px', marginTop: "20px" }}>
                <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Cairo, sans-serif' }}>
                  أحدث المقالات
                </Typography>

                <List>
                  {latestArticles.map((article) => (
                    <ListItem
                      key={article.id}
                      sx={{ cursor: 'pointer' }} // Make the whole item clickable with pointer cursor
                      onClick={() => handleCardClick(article)} // Open dialog on item click
                    >
                      <Box style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div>
                          <Typography style={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif' }} variant="subtitle1">
                            {article.title}
                          </Typography>
                          <Typography style={{ textAlign: 'right', fontFamily: 'Cairo, sans-serif' }} variant="caption" display="block" gutterBottom>
                            {article.created_at}
                          </Typography>
                        </div>
                        <div>
                          <img
                            style={{
                              height: "70px",
                              minWidth: "70px",
                              borderRadius: "10px",
                              border: '2px solid #1A73E8',
                              objectFit: 'cover',
                            }}
                            src={article.image}
                            alt={article.title}
                          />
                        </div>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </Paper>

              {/* Categories */}
              <Paper elevation={3} sx={{ padding: '20px' }}>
                <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Cairo, sans-serif' }}>
                  تصنيفات المقالات
                </Typography>

                <Grid container spacing={2}>
                  {/* Render unique categories dynamically */}
                  {categories.map((category, index) => (
                    <Grid item xs={6} key={index}>
                      <Chip
                        label={category}
                        clickable
                        onClick={() => handleCategoryClick(category)}
                        sx={{
                          fontFamily: 'Cairo, sans-serif',
                          width: '100%',
                          backgroundColor: selectedCategory === category ? '#1A73E8' : 'default',
                          color: selectedCategory === category ? '#fff' : 'default'
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}

        {/* Dialog for showing full article details */}
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="md"
          sx={{ direction: 'rtl', fontFamily: 'Cairo, sans-serif' }}
        >
          {selectedArticle && (
            <>
              <DialogTitle sx={{ fontFamily: 'Cairo, sans-serif', textAlign: 'center' }}>
                {selectedArticle.title}
              </DialogTitle>
              <DialogContent dividers>
                <Typography sx={{ fontFamily: 'Cairo, sans-serif', marginBottom: '20px' }}>
                  {selectedArticle.description}
                </Typography>
                <CardMedia
                  component="img"
                  image={selectedArticle.image}
                  alt={selectedArticle.title}
                  sx={{ width: '100%', height: 'auto', borderRadius: '8px', objectFit: 'cover', marginBottom: '20px' }}
                />
                <Typography variant="caption" sx={{ fontFamily: 'Cairo, sans-serif', display: 'block' }}>
                  {selectedArticle.created_at}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary" sx={{ fontFamily: 'Cairo, sans-serif' }}>
                  إغلاق
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </Box>
    </>
  );  
};
    export default ArticlesPage;
         