import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import { Box, Card, CardContent, Typography, CircularProgress } from '@mui/material';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { ThemeContext } from '../ThemeContext';

const sliderSettings = {
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const CardSlider = () => {
  const { themeMode } = useContext(ThemeContext); // Access the theme mode
  const isDarkMode = themeMode === 'dark'; // Determine if dark mode is active
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    // Fetch card slider data dynamically from the API
    const fetchData = async () => {
      try {
        const response = await axios.get('https://backendforerpsystem.zbooma.com/getCardSlider.php');
        if (response.data.success) {
          setCardData(response.data.data); // Update cardData with API response
        } else {
          console.error('Failed to fetch card slider data');
        }
      } catch (error) {
        console.error('Error fetching card slider data:', error);
      } finally {
        setLoading(false); // Stop loading spinner after data is fetched
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <hr style={{ width: "96%" }} />
      <Box 
        sx={{ 
          marginTop: "30px",
          marginBottom: "30px",
          padding: { xs: '20px 10px', md: '30px 20px' }, 
          textAlign: 'center', 
          fontFamily: 'Cairo, sans-serif', 
          backgroundColor: isDarkMode ? '#121212' : '#30355b' // Dark mode background
        }}
      >
        <Box sx={{ width: '100%', margin: '0 auto' }}>
          {loading ? ( // Show a loading spinner while fetching data
            <CircularProgress />
          ) : (
            <Slider {...sliderSettings}>
              {cardData.map((card, index) => (
                <Box key={index} sx={{ padding: '10px' }}>
                  <Card 
                    sx={{ 
                      display: 'inline-block', 
                      borderRadius: '16px', 
                      minWidth: 250, 
                      maxWidth: 300, 
                      backgroundColor: isDarkMode ? '#1e1e1e' : '#ffffff', // Card background color
                      boxShadow: isDarkMode ? '0 4px 8px rgba(255, 255, 255, 0.2)' : '0 4px 8px rgba(0, 0, 0, 0.1)', // Card shadow
                      mx: 'auto' 
                    }}
                  >
                    <CardContent 
                      sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center', 
                        padding: '20px' 
                      }}
                    >
                      <Typography 
                        variant="h6" 
                        fontWeight="bold" 
                        sx={{ 
                          marginBottom: '10px', 
                          color: isDarkMode ? '#ffffff' : '#000000' // Title text color
                        }}
                      >
                        {card.title}
                      </Typography>
                      <Box 
                        sx={{ 
                          display: 'flex', 
                          flexWrap: 'wrap', 
                          justifyContent: 'center', 
                          gap: '20px', 
                          mt: 1 
                        }}
                      >
                        {card.images?.map((image, imageIndex) => (
                          <Box 
                            key={imageIndex} 
                            sx={{ 
                              display: 'flex', 
                              flexDirection: 'column', 
                              alignItems: 'center', 
                              mb: 1 
                            }}
                          >
                            <img 
                              src={`https://backendforerpsystem.zbooma.com/${image}`} // Dynamic image path
                              alt={`icon-${imageIndex}`} 
                              style={{ 
                                height: 50, 
                                margin: '10px 0', 
                                filter: isDarkMode ? 'invert(1)' : 'none' // Image filter for dark mode
                              }} 
                            />
                          </Box>
                        ))}
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              ))}
            </Slider>
          )}
        </Box>
      </Box>
      <style jsx global>{`
        @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;600;700&display=swap');
        
        body {
          font-family: 'Cairo', sans-serif;
        }
      `}</style>
    </div>
  );
};

export default CardSlider;
