import React, { useState, useEffect } from 'react';
import { Phone, Language, LocationOn } from '@mui/icons-material';
import './ContactUsSection.css';

const baseURL = 'https://backendforerpsystem.zbooma.com'; // Base URL for image

const ContactUsSection = () => {
  const [contactData, setContactData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data from API
  useEffect(() => {
    fetch('https://backendforerpsystem.zbooma.com/contact_us_section_protofilo.php')
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setContactData(data.data); // Set the contact data from the API
        } else {
          setError('Failed to fetch contact data');
        }
        setLoading(false); // Stop loading after the data is fetched
      })
      .catch(err => {
        setError('An error occurred while fetching data');
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="contact-section">
      <div className="contact-image">
        {/* Display the fetched image dynamically */}
        <img src={`${baseURL}/${contactData.image_path}`} alt="Contact" />
      </div>
      <div className="contact-info">
        <h2>{contactData.title}</h2>
        <p>{contactData.description}</p>
        <div className="contact-details">
          <div className="contact-item">
            <Phone />
            <span>{contactData.phone}</span>
          </div>
          <div className="contact-item">
            <Language />
            <span>{contactData.web_address}</span>
          </div>
          <div className="contact-item">
            <LocationOn />
            <span>{contactData.location}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsSection;
