import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import image from "../../images/Group-5.png"
// Example logos
const logos = [
  { id: 1, src:image, alt: 'Logo 1' },
  { id: 2, src: image, alt: 'Logo 2' },
  { id: 3, src: image, alt: 'Logo 3' },
  { id: 4, src: image, alt: 'Logo 4' },
  { id: 5, src: image, alt: 'Logo 5' },
  { id: 6, src: image, alt: 'Logo 6' },
  { id: 7, src: image, alt: 'Logo 7' },
  { id: 8, src: image, alt: 'Logo 8' },
  { id: 9, src: image, alt: 'Logo 9' },
  { id: 10, src: image, alt: 'Logo 10' }, 
   { id: 5, src: image, alt: 'Logo 5' },
  { id: 6, src: image, alt: 'Logo 6' },
  { id: 7, src: image, alt: 'Logo 7' },
  { id: 8, src: image, alt: 'Logo 8' },
  { id: 9, src: image, alt: 'Logo 9' },
  { id: 10, src: image, alt: 'Logo 10' },  
  { id: 6, src: image, alt: 'Logo 6' },
  { id: 7, src: image, alt: 'Logo 7' },
];

const LogosSection = () => {
  return (
    <>
    
      {/* <hr style={{width:"90%",margin:"auto"}}/>
    <Box sx={{ width: '100%', padding: '30px 0', backgroundColor: '#30355b' }}>
      <Box sx={{ width: '90%', margin: 'auto' }}>
        <Typography 
          variant="h5" 
          sx={{ 
            color: 'white', 
            fontFamily: '"MO_Nawel", sans-serif', 
            textAlign: 'right', 
            fontSize: '40px', 
            marginBottom: '30px' 
          }}
        >
          شركـاء النـجـاح
        </Typography>
        <Grid container spacing={1} justifyContent="center">
          {logos.map((logo) => (
               <Grid 
               item 
               xs={6} // Two logos per row on extra-small screens (phones)
               sm={4} 
               md={3} 
               lg={2} 
               key={logo.id} 
               sx={{ display: 'flex', justifyContent: 'center' }}
             >
              <Box
                sx={{
                  backgroundColor: '#30355b',
                  padding: '20px',
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '150px',
                  width: '150px',
                }}
              >
                <img 
                  src={logo.src} 
                  alt={logo.alt} 
                  style={{ 
                    maxWidth: '100%', 
                    maxHeight: '100%' 
                  }} 
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box> */}
    </>
  );
};

export default LogosSection;
