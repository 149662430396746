import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Paper } from '@mui/material';
import axios from 'axios';

const API_URL = 'https://backendforerpsystem.zbooma.com/OurServices.php';

const OurServicesManager = () => {
  const [mainTitle, setMainTitle] = useState('');
  const [sliderOne, setSliderOne] = useState([{ name: '', image: null, preview: null }]);
  const [sliderTwo, setSliderTwo] = useState([{ name: '', image: null, preview: null }]);

  useEffect(() => {
    axios.get(API_URL)
      .then(response => {
        const data = response.data;
        setMainTitle(data.main_title);
        setSliderOne(JSON.parse(data.slider_one).map(slide => ({
          ...slide,
          preview: slide.image ? `https://backendforerpsystem.zbooma.com/${slide.image}` : null
        })));
        setSliderTwo(JSON.parse(data.slider_two).map(slide => ({
          ...slide,
          preview: slide.image ? `https://backendforerpsystem.zbooma.com/${slide.image}` : null
        })));
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleInputChange = (e, index, slider, setSlider) => {
    const { name, value } = e.target;
    const newSlider = [...slider];
    newSlider[index][name] = value;
    setSlider(newSlider);
  };

  const handleFileChange = (e, index, slider, setSlider) => {
    const file = e.target.files[0];
    const newSlider = [...slider];
    newSlider[index]['image'] = file;
    newSlider[index]['preview'] = URL.createObjectURL(file); // Create preview URL
    setSlider(newSlider);
  };

  const handleAddSlide = (setSlider) => {
    setSlider(prev => [...prev, { name: '', image: null, preview: null }]);
  };

  const handleRemoveSlide = (index, slider, setSlider) => {
    const newSlider = slider.filter((_, i) => i !== index);
    setSlider(newSlider);
  };

  const handleSave = () => {
    const formData = new FormData();
    formData.append('main_title', mainTitle);
    
    sliderOne.forEach((slide, index) => {
      formData.append(`slider_one[${index}][name]`, slide.name);
      if (slide.image instanceof File) {
        formData.append(`slider_one_image_${index}`, slide.image);
      } else if (slide.image) {
        formData.append(`slider_one[${index}][image]`, slide.image);
      }
    });
  
    sliderTwo.forEach((slide, index) => {
      formData.append(`slider_two[${index}][name]`, slide.name);
      if (slide.image instanceof File) {
        formData.append(`slider_two_image_${index}`, slide.image);
      } else if (slide.image) {
        formData.append(`slider_two[${index}][image]`, slide.image);
      }
    });
  
    axios.post(API_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        console.log('Response data:', response.data);
        alert(response.data.message);
      })
      .catch((error) => {
        console.error('Error saving data:', error);
        if (error.response) {
          console.error('Server response:', error.response.data);
        }
      });
  };

  const renderSlide = (slide, index, sliderName) => (
    <Paper key={index} sx={{ padding: '10px', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
      <TextField 
        label="Name" 
        name="name" 
        value={slide.name} 
        onChange={(e) => handleInputChange(e, index, sliderName === 'sliderOne' ? sliderOne : sliderTwo, sliderName === 'sliderOne' ? setSliderOne : setSliderTwo)} 
        sx={{ flex: 1, marginRight: '10px' }}
      />
      <input 
        type="file" 
        onChange={(e) => handleFileChange(e, index, sliderName === 'sliderOne' ? sliderOne : sliderTwo, sliderName === 'sliderOne' ? setSliderOne : setSliderTwo)} 
        style={{ marginRight: '10px' }}
      />
      {slide.preview && (
        <img 
          src={slide.preview} 
          alt={slide.name} 
          style={{ width: '100px', height: '100px', objectFit: 'contain' }} 
        />
      )}
      <Button onClick={() => handleRemoveSlide(index, sliderName === 'sliderOne' ? sliderOne : sliderTwo, sliderName === 'sliderOne' ? setSliderOne : setSliderTwo)}>
        Remove
      </Button>
    </Paper>
  );

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h4" mb={2}>Manage Our Services</Typography>
      <TextField 
        label="Main Title" 
        fullWidth 
        value={mainTitle} 
        onChange={(e) => setMainTitle(e.target.value)} 
        sx={{ marginBottom: '20px' }}
      />

      <Typography variant="h6">Slider One</Typography>
      {sliderOne.map((slide, index) => renderSlide(slide, index, 'sliderOne'))}
      <Button onClick={() => handleAddSlide(setSliderOne)}>Add Slide</Button>

      <Typography variant="h6" mt={4}>Slider Two</Typography>
      {sliderTwo.map((slide, index) => renderSlide(slide, index, 'sliderTwo'))}
      <Button onClick={() => handleAddSlide(setSliderTwo)}>Add Slide</Button>

      <Button variant="contained" color="primary" onClick={handleSave} sx={{ marginTop: '20px' }}>
        Save
      </Button>
    </Box>
  );
};

export default OurServicesManager;
