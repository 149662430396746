import React, { useState, useEffect } from 'react';
import {
  Card, CardMedia, Grid, Typography, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2'; // Import SweetAlert2

const ServicesImagesProtofiloManager = () => {
  const [tabs, setTabs] = useState([]);
  const [editingTab, setEditingTab] = useState(null);
  const [label, setLabel] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [updatedImages, setUpdatedImages] = useState([]);
  const baseURL = 'https://backendforerpsystem.zbooma.com'; // Base URL for your images

  // Fetch the data from the API
  useEffect(() => {
    fetch('https://backendforerpsystem.zbooma.com/get_services_images_protofilo.php') // API endpoint
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          const updatedData = data.data.map(tab => ({
            ...tab,
            images: tab.images.map(image =>
              image.startsWith('http') ? image : `${baseURL}${image}`
            )
          }));
          setTabs(updatedData);
        }
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch data',
          icon: 'error',
          customClass: {
            popup: 'swal2-zindex'
          }
        });
        console.error('Error fetching data:', error);
      });
  }, []);

  // Handle the edit button click for a tab
  const handleEditClick = (tab) => {
    setEditingTab(tab);
    setLabel(tab.label);
    setUpdatedImages(tab.images); // Load existing images
    setSelectedFiles([]);
    setOpen(true);
  };

  // Handle image file selection
  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  // Handle deleting an image with confirmation inside dialog
  const handleDeleteImage = (image) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this image?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
      customClass: {
        popup: 'swal2-zindex'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        // Send the entire updated images array, and specify the image to delete
        fetch('https://backendforerpsystem.zbooma.com/delete_services_image.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            image: image, // The image URL to be deleted
            id: editingTab.id, // The ID of the service/tab
            images: updatedImages // Send the entire images array
          }),
        })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            // Update the images list in the dialog
            setUpdatedImages(updatedImages.filter(img => img !== image)); // Remove image from UI
            Swal.fire({
              title: 'Deleted!',
              text: 'Image has been deleted.',
              icon: 'success',
              customClass: {
                popup: 'swal2-zindex'
              }
            });
          } else {
            Swal.fire({
              title: 'Error',
              text: 'Failed to delete image.',
              icon: 'error',
              customClass: {
                popup: 'swal2-zindex'
              }
            });
          }
        })
        .catch(error => {
          console.error('Error deleting image:', error);
          Swal.fire({
            title: 'Error',
            text: 'An error occurred while deleting the image.',
            icon: 'error',
            customClass: {
              popup: 'swal2-zindex'
            }
          });
        });
      }
    });
  };

  // Handle adding new images
  const handleAddImages = () => {
    if (selectedFiles.length > 0) {
      const newImages = Array.from(selectedFiles).map(file => URL.createObjectURL(file));
      setUpdatedImages([...updatedImages, ...newImages]);
      Swal.fire({
        title: 'Added!',
        text: 'New images have been added.',
        icon: 'success',
        customClass: {
          popup: 'swal2-zindex'
        }
      });
    }
  };

  // Handle saving all changes (label and images)
  const handleSave = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to save the changes?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, save it!',
      cancelButtonText: 'No, cancel',
      customClass: {
        popup: 'swal2-zindex'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append('id', editingTab.id);
        formData.append('label', label);

        // Add newly uploaded images
        Array.from(selectedFiles).forEach((file, index) => {
          formData.append(`images[]`, file);
        });

        formData.append('existing_images', JSON.stringify(updatedImages)); // Send existing images

        fetch('https://backendforerpsystem.zbooma.com/update_services_images_protofilo.php', {
          method: 'POST',
          body: formData,
        })
          .then((response) => response.text())
          .then((text) => {
            try {
              const data = JSON.parse(text);
              if (data.success) {
                setTabs(
                  tabs.map((tab) =>
                    tab.id === editingTab.id
                      ? { ...tab, label, images: data.images }
                      : tab
                  )
                );
                setOpen(false);
                Swal.fire({
                  title: 'Success',
                  text: 'Changes have been saved successfully.',
                  icon: 'success',
                  customClass: {
                    popup: 'swal2-zindex'
                  }
                });
              } else {
                Swal.fire({
                  title: 'Error',
                  text: 'Failed to save changes.',
                  icon: 'error',
                  customClass: {
                    popup: 'swal2-zindex'
                  }
                });
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
              Swal.fire({
                title: 'Error',
                text: 'An unexpected error occurred. Check the console for details.',
                icon: 'error',
                customClass: {
                  popup: 'swal2-zindex'
                }
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: 'Error',
              text: 'An error occurred while saving changes.',
              icon: 'error',
              customClass: {
                popup: 'swal2-zindex'
              }
            });
            console.error('Error updating data:', error);
          });
      }
    });
  };

  return (
    <div style={{ padding: '20px' }}>
      {tabs.map((tab) => (
        <div key={tab.id} style={{ marginBottom: '40px' }}>
          {/* Center the label with professional style */}
          <Typography variant="h5" align="center" gutterBottom style={{ fontWeight: 'bold', marginBottom: '20px' }}>
            {tab.label}
          </Typography>
          <Grid container spacing={2}>
            {tab.images.map((image, index) => (
              <Grid item xs={12} sm={6} md={2} key={index}> {/* Smaller Image Card */}
                <Card>
                  <CardMedia
                    component="img"
                    height="100" // Smaller height for images
                    image={image}
                    alt={`Image ${index}`}
                    style={{ objectFit: 'cover' }}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleEditClick(tab)}
            style={{ marginTop: '10px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
          >
            Edit
          </Button>
        </div>
      ))}

      {/* Edit Dialog */}
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Edit {editingTab?.label}</DialogTitle>
        <DialogContent>
          <TextField
            label="Label"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            fullWidth
            margin="normal"
          />
          <div style={{ marginBottom: '10px' }}>
            <Typography variant="body1">Existing Images:</Typography>
            <Grid container spacing={2}>
              {updatedImages.map((image, index) => (
                <Grid item xs={6} key={index}>
                  <div style={{ position: 'relative' }}>
                    <Card>
                      <CardMedia
                        component="img"
                        height="100"
                        image={image}
                        alt={`Image ${index}`}
                        style={{ objectFit: 'cover' }}
                      />
                      {/* Delete Icon */}
                      <IconButton
                        aria-label="delete"
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        }}
                        onClick={() => handleDeleteImage(image)}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Card>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>

          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleFileChange}
            style={{ marginTop: '10px' }}
          />
          <Button
            variant="contained"
            onClick={handleAddImages}
            color="primary"
            style={{ marginTop: '10px' }}
          >
            Add Selected Images
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ServicesImagesProtofiloManager;
