import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { TextField, Button, Card, CardContent, Typography, Grid } from '@mui/material';

const StatsSectionManager = () => {
  const [stats, setStats] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedStat, setEditedStat] = useState({ id: '', stat_value: '', stat_description: '' });

  useEffect(() => {
    fetch('https://backendforerpsystem.zbooma.com/stats_section_cards.php')
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setStats(data.data);  // Now includes the 'id'
        }
      })
      .catch(error => console.error('Error fetching stats:', error));
  }, []);

  const handleEditClick = (index) => {
    setEditingIndex(index);
    setEditedStat({
      id: stats[index].id,  // Set the 'id' for the stat being edited
      stat_value: stats[index].stat_value,
      stat_description: stats[index].stat_description
    });
  };

  const handleCancelClick = () => {
    setEditingIndex(null);  // Exit editing mode
    setEditedStat({ id: '', stat_value: '', stat_description: '' });  // Reset editedStat
  };

  const handleSaveClick = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to save the changes?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, save it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch('https://backendforerpsystem.zbooma.com/update_stats_section_cards.php', {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              id,  // Now 'id' is correctly passed to the backend
              stat_value: editedStat.stat_value,
              stat_description: editedStat.stat_description,
            }),
          })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              const updatedStats = [...stats];
              updatedStats[editingIndex] = { ...editedStat, id };  // Update the stats in the state
              setStats(updatedStats);
              setEditingIndex(null);
              Swal.fire('Success!', 'The stat has been updated.', 'success');
            } else {
              Swal.fire('Error!', data.message, 'error');
              console.error('Error from backend:', data.message);
            }
          })
          .catch((error) => {
            console.error('Error updating stats:', error);
            Swal.fire('Error!', 'An error occurred while updating the stat.', 'error');
          });
      }
    });
  };

  const handleChange = (e) => {
    setEditedStat({
      ...editedStat,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '50px' }}>
      <Grid container spacing={2} justifyContent="center" maxWidth="lg">
        {stats.map((stat, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}> {/* Set md={3} for 4 cards in a row */}
            <Card style={{ padding: '20px', height: '100%' }}> {/* Make cards bigger */}
              <CardContent>
                {editingIndex === index ? (
                  <div>
                    <TextField
                      label="Value"
                      variant="outlined"
                      name="stat_value"
                      value={editedStat.stat_value}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Description"
                      variant="outlined"
                      name="stat_description"
                      value={editedStat.stat_description}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
                      <Button variant="contained" color="primary" onClick={() => handleSaveClick(editedStat.id)}>
                        Save
                      </Button>
                      <Button variant="outlined" color="secondary" onClick={handleCancelClick}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div style={{ textAlign: 'center' }}> {/* Center text inside the card */}
                    <Typography variant="h4">{stat.stat_value}</Typography>
                    <Typography variant="body1">{stat.stat_description}</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginTop: '16px' }}
                      onClick={() => handleEditClick(index)}
                    >
                      Edit
                    </Button>
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default StatsSectionManager;
